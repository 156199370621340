import { Message } from './message';

export const MessageList = (props) => {

    const { user, id_chat, messages, abrirFuente, userPermission } = props

    if(messages.length===0) return;

    return(
    <>
     {
      messages.map( (message, index) =>
       (
        <Message
          key={`mensaje-${index}`}
          user={user}
          id_chat={id_chat}
          message={message}
          abrirFuente={abrirFuente}
          userPermission={userPermission}
        />
       )
      )
     }
     
    </>)
}