import React from 'react'
import NavLink from '../NavLink';

const ConfigurationSidebar = (props) => {

  const {onOpenModal } = props;
  return (
    <div className={`flex h-screen duration-300 transform w-64`}>
      <div className={` h-screen z-30 w-full overflow-y-auto transition duration-300 transform bg-sliderbar`}>
        <h1 className="text-white text-2xl text-center m-3">Configuración</h1>
        <div className="flex items-center flex-col w-full">

          <NavLink icon="/icons/person.png" url="/configuracion" text="Datos personales" />
          <NavLink icon="/icons/security.png" url="/configuracion/seguridad" text="Seguridad" />

          <button onClick={onOpenModal} 
                className="flex items-center justify-center px-6 py-2 mt-4 text-gray-100 bg-gray-700 bg-opacity-25 w-full"
            
          > <i> </i> Eliminar cuenta</button>

        </div>


      </div>
    
    </div>
  )
}

export default ConfigurationSidebar;