import React, { useId, useState } from 'react';
import StoreSelection from '../components/store/storeSelection';
import LexcoreStore from './stores/lexcoreStore';

const Store = (props) => {
  const { user, updateToken } = props;
  const [activeStore, setActiveStore] = useState(2);

  const stores = [
    /*{
      id: 1,  
      name: "A-Dike",
      component: <AdikeStore key={useId} user={user} updateToken={updateToken} active={activeStore}/>
    },*/
    {
      id: 2,  
      name: "Lexcore",
      component: <LexcoreStore key={useId} user={user} updateToken={updateToken} active={activeStore}/>
    },
  ]

  const changeStore = ({store}) =>{
    setActiveStore(store.id);
  };

  //Vista de la tienda (Store)
  return (
    <div className='flex w-full flex-col px-12 py-5'>
      <div className="text-4xl my-10">
        <span className="text-gray-100 font-semibold">Bienvenido a la Tienda!</span><br/>
        <span className="text-gray-400 font-semibold text-lg leading-3">Explora nuestros productos y contratos que tenemos para tí. Buscalos con IA.</span>
      </div>
      <div className="flex justify-between mb-8 flex-wrap w-full">
        {<StoreSelection onClick={changeStore} store={stores} active={activeStore} />}
      </div>
      {stores.map(store => store.id === activeStore && store.component)}
    </div>
  )
}

export default Store;