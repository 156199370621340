import {Modal} from "./modal/modal";
import {ModalHeader} from "./modal/modalHeader";
import {ModalBody} from "./modal/modalBody";
import {ModalFooter} from "./modal/modalFooter";

export const ModalConfirmation = ({isOpen,item, accion, onClose, loading, pregunta, textoConfirmar, textoCancelar}) => {


    const confirmarCompra = () => {
     window.location.replace(item.url)
    }

  
    if(!isOpen) return("");

    if(!item || loading) return (<Modal active={isOpen} action={()=>{}}><p className="p-1 text-center animate-pulse"> Cargando...</p></Modal>)

    return(
        <Modal active={isOpen} action={()=>{}}>

          <ModalHeader 
           titulo=""
           urlImagen="carrito-de-compras.png"
           onClose={onClose}
          />

          <ModalBody>
           <h1 className="text-xl font-bold" >{pregunta}</h1>
           <h2 className="text-lg font-semibold">{item.name}</h2>
           <h2>{item.description}</h2>
           <h2 className="font-semibold text-lg" >{`$${item.price} ${item.currency}`}</h2>
          </ModalBody>

          <ModalFooter>
            <button onClick={onClose} className="bg-secondarycolorback text-white rounded p-2" >{textoCancelar}</button>
            <button onClick={confirmarCompra} className=" bg-green-700 text-white p-2 rounded" >{textoConfirmar}</button>
          </ModalFooter>

        </Modal>
    )

}