import { NavLink } from "react-router-dom";
import { analytics } from '../../config/firebase';

const ChatNavLink = (props) => {

  const {user, id_chat, url, text, action} = props;
  return (
    <div 
      className="flex items-center px-6 py-2 mt-4 text-gray-100 bg-secondarycolorback bg-opacity-25 flex-row w-full rounded"
      onClick={()=> {
        analytics('seleccion_chat', {chat:id_chat, user});
        action();}}
    >
      <NavLink to={url} className="flex flex-row">
        <img
          alt="dashboard icon"
          src= '/icons/chat.png'
          className="w-6 h-6"
          onClick={action}
        />
        <span className='mx-3'>{text}</span>
      </NavLink>
      <button>...</button>
    </div>
  )
}

export default ChatNavLink;