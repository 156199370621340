const NewUser = (props) => {

  const { action } = props;

  return (
    <button
      className="w-20 rounded-xl bg-sky-800 text-white text-xl flex justify-center text-center py-1"
      onClick={action}
    >
      +
    </button>
  )
};

export default NewUser;