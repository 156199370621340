import React from "react";
import Select from 'react-select';

const Selection = (props)=>{
    const { opciones, selected, action, isMulti } = props;

    return(
        <div className="w-28">
        <Select  
            options={opciones} 
            defaultValue={selected}
            onChange={action}
            className="selection"
            isMulti={isMulti}
            styles={{
                control: (base, state) => ({
                  ...base,
                  background: "rgba(0,0,0,0)",
                  color: "black",
                  borderColor: state.isFocused ? 'border-transparent' : 'border-transparent'
                })
              }}
            theme={(theme)=> ({
              ...theme,
              colors: {
                ...theme.colors,
                text: "#000",
                neutral0: '#fff',
                neutral10: "#B53D51",
                neutral20: "#000",
                neutral50: "#000",
                neutral60: "#000",
                neutral80: "#000",
                primary: "#3D51B5",
                
              }
            })}
        />
      </div>
    );
}

export default Selection;