import { SCJNMessage } from "./scjnMessage";

export const SCJNMessageList = (props) => {

  const { options, fuente, idDetalle } = props

  if (options.length === 0) return;

  return (
    <section className="flex flex-col gap-5 border-b-5 border-blue-30 border-solid my-10">
      <p className="bg-primarycolorcard p-2 font-bold rounded text-white">Pregunta: {fuente} </p>
      <span className=" text-white">Busqueda - {options.busqueda}</span>
      {
        ('contenido' in options && options.contenido.length > 0) ? (
          <SCJNMessage options={options} idDetalle={idDetalle} />
        ): (
          <span className=" text-white">Sin resultados</span>
        )
      }
    </section>
  )
}