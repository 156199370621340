import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import trackPathForAnalytics from './config/track';
import Login from './views/login';
import Sidebar from './components/sideBar';
import Usuarios from './views/usuarios';
import Config from './views/config';
import './index.css';
import Dashboard from './views/dashboard';
import Store from './views/store';
import Subscription from './views/subscription';
import { ChatLayout } from './components/chat/chatLayout';
import { Chat } from './views/chat';
import { ConfigurationLayout } from './components/configuracion/configurationLayout';
import { DatosPersonales } from './views/configuracion/datosPersonales';
import { Seguridad } from './views/configuracion/seguridad';
import { getIdTokenResult } from "firebase/auth";

import { ErrorPruebas } from './views/errorPruebas';
import UserProducts from './views/user-products';
import LexcoreMappings from './views/mappings/lexcoreMappings';
import Aviso from './views/aviso';
import Terminos from './views/terminos';


const App = () => {

  const { pathname, search } = useLocation();
  const auth = getAuth();
  const [user, setUser] = useState({});
  const tourViewed = window.localStorage.getItem("KEY-SIDEBAR");
  const [userPermission, setUserPermission] = useState(undefined);
  const [userStatus, setUserStatus] = useState(false);
  const [isUpgradeOpen, setIsUpgradeOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(tourViewed ? false : true);
  const [freeAccess, setFreeAccess] = useState(false);
  const navigate = useNavigate()

  const abrirModalUpgrade = () => { setIsUpgradeOpen(!isUpgradeOpen) };

  const analytics = useCallback(() => {
    trackPathForAnalytics({ path: pathname, search: search, title: pathname.split("/").slice(1).toString().replace(',', '/') });
  }, [pathname, search]);

  const logOut = async () => {
    await signOut(auth);
    analytics("logout_usuario", { user })
    navigate('/login');
  }

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user && user.uid) {
        const { claims } = await getIdTokenResult(user, true);
        setUser(user)
        setUserPermission(
          'permission' in claims &&
          'subscription' in claims.permission &&
          claims.permission.subscription
        );
        setUserStatus('status' in claims && claims.status);
        if (
          "freeAccess" in claims &&
          claims.freeAccess !== undefined
        ) {
          const currentDate = new Date().getTime();
          const timeDifference = parseInt(claims.freeAccess) - currentDate;
          // Convertir la diferencia de milisegundos a días
          const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
          setFreeAccess(daysRemaining);
        }
      } else {
        if(pathname !== '/login'  && pathname !== '/politicas' && pathname !== '/terminos'){
          setUser({});
          navigate('/login');
        }
      }
    });
    analytics();
    return () => {
      setUser('');
      setFreeAccess(false);
    }
  }, [auth, navigate, analytics]);

  const updateToken = async (user) => {
    await getIdTokenResult(user, true);
  }

  const sidebar = user.uid && (
    <Sidebar
      user={user}
      userPermission={userPermission}
      userStatus={userStatus}
      logOut={logOut}
      action={() => setIsNavOpen(!isNavOpen)}
      open={isNavOpen}
      onOpenUpgrade={abrirModalUpgrade}
      freeAccess={freeAccess}
    />);
  return (
    <div className="w-full flex flex-row bg-primarycolorback min-h-screen">
      {sidebar}
      <div className={`h-screen overflow-y-auto w-full`}>
        <Routes>
          <Route index element={<Dashboard user={user} userPermission={userPermission} userStatus={userStatus} />} path="/" />
          <Route element={<ChatLayout user={user} userPermission={userPermission} />} path="/chat" >
            <Route index element={<Chat />} path=":id_chat" />
          </Route>
          <Route element={<Login user={user} navigate={navigate} />} exact path="/login" />
          <Route element={<Usuarios user={user} userPermission={userPermission} userStatus={userStatus} />} exact path="/usuarios/:userName?" />
          <Route element={<Config user={user} userPermission={userPermission} userStatus={userStatus} />} exact path="/token" />
          {<Route element={<Store user={user} userPermission={userPermission} userStatus={userStatus} updateToken={updateToken} />} exact path="/tienda" />}
          {<Route element={<UserProducts user={user} navigate={navigate} userPermission={userPermission} userStatus={userStatus} updateToken={updateToken} />} exact path="/mis-productos" />}
          <Route element={<LexcoreMappings user={user} navigate={navigate} />} exact path="/mis-productos/mappings/" />

          <Route element={<Subscription user={user} userPermission={userPermission} userStatus={userStatus} updateToken={updateToken} />} exact path="/actualizar" />
          <Route element={<ConfigurationLayout user={user} userPermission={userPermission} userStatus={userStatus} />} path="/configuracion">
            <Route index element={<DatosPersonales />} />
            <Route element={<Seguridad />} path="seguridad" />
          </Route>
          <Route element={<ErrorPruebas />} path="pruebaError" />
          <Route element={<Aviso />} path="/politicas" />
          <Route element={<Terminos />} path="/terminos" />
        </Routes>
      </div>
    </div>
  );

}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <App />
  </Router>
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/serviceWorker.js`)
      .then(registration => console.log('Service worker registered'))
      .catch(error => console.error('Error registering service worker', error));
  });
}
