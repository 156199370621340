import React, { useState, useEffect } from 'react';
import NavbarProducts from '../../components/store/navbarProducts';
import Search from '../../components/search';
import ProductGrid from '../../components/store/productGrid';
import { getQueryWhere } from '../../config/firestore';
import { useParams } from 'react-router-dom';
import { ModalConfirmation } from '../../components/modalConfirmation';
import { ModalSuccess } from '../../components/modalSuccess'; 
import { ModalError } from '../../components/modalError';
import { get } from '../../config/axios';
import { analytics } from '../../config/firebase';
import { LEXCORE_URL } from '../../constants';

const LexcoreStore = (props) => {

  const estadoCompra = new URLSearchParams(window.location.search).get('estado_compra');
  const mostrarModalSuccess = estadoCompra === "finalizada" ? true : false;

  const { productName } = useParams();
  const { user, updateToken } = props;
  const [products, setProducts] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({"id":null})
  const [query, setQuery] = useState('');
  const [searching, setSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingStripe, setLoadingStripe] = useState(false);
  const [error, setError] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(mostrarModalSuccess);
  const [openErrorLoading, setOpenErrorLoading] = useState(false);
  const [openErrorStripe, setOpenErrorStripe] = useState(false);

  const tipo_productos = ["Renta de abogados", "Contratos", "Tramites"]

  useEffect(() => {
    if(user?.uid && estadoCompra==="finalizada") {
      analytics("producto_comprado", {user})
      updateToken(user)
    }
    if ((!products && !searching) || refetch) getProducts();
    if (productName && products.length === 0) {
      const obtenerProducto = async () => {
        const { docs } = await getQueryWhere('products', ["name", productName]);
        if (docs) {
          const data = docs.map(d => d.data());
          setProducts(data);
        }
      }
      obtenerProducto()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, products, refetch, estadoCompra]);

  
  const cerrarModalConfirmacion = () => {
    setOpenConfirm(false);
    setSelectedProduct(null)
  }

  const formatearTexto = (texto) => {
    const textoSinAcentos = texto.normalize("NFD").replace(/[\u0300-\u036f]/g,"");
    const textoMinusculas = textoSinAcentos.toLowerCase();
    return textoMinusculas;
  }

  const procesarFormatoProductos = (productos) => {
    const productosFormateados = productos.map( producto => ({"nombreFormateado": formatearTexto(producto.name), ...producto}));
    return productosFormateados;
  }

  const obtenerDatosProductoSeleccionado = async (id) => {
    analytics('intencion_de_comprar_lexcore', {nombre: id, user});
        setLoadingStripe(true);
        setSelectedProduct({"id":id});
        setOpenConfirm(true);
        try
        {
         const {data} = await get(LEXCORE_URL+`/store/product-info?product=${id}`, 
          {
           headers: 
           {
            Authorization: `Bearer ${user.accessToken}`
           }
          });
         if(data) 
         {
          setSelectedProduct(data);
          setLoadingStripe(false); 
         }
        }catch(e){
         setOpenConfirm(false);
         setLoadingStripe(false);
         setOpenErrorStripe(true);
        }
  }

  const searchQuery = async (e) =>
  {
   const valor = e.target.value;
   setQuery(valor);
   if(valor.length > 5)
   {
     setSearching(true);
     try
     {
      const {data} = await get(LEXCORE_URL+`/store/product-search?query=${valor}`,{
        headers: 
        {
         Authorization: `Bearer ${user.accessToken}`
        }
       });
      if(data)
      {
       const productos = data.map(d => d);
       const productosFormateados = procesarFormatoProductos(productos);
       setFilteredProducts(productosFormateados);
       if(productosFormateados.length > 0) 
       {
        setSearching(false);
       }
      }
     }catch(e){
      setSearching(false)
      setError(true);
      setOpenErrorLoading(true);
 
      
     }
    }
   else 
   {
    setProducts([]);
    setSearching(false)
    setRefetch(true);
   }  
  }

  const refetchProducts = () => {
   setIsLoading(true);
   setError(false)
   setOpenErrorLoading(false)
   setRefetch(true);
  }

  const getProducts = async () => {
   setIsLoading(true);
   setRefetch(false);
   if(!navigator.onLine)
   {
    setIsLoading(false);
    setError(true);
    setOpenErrorLoading(true);
   }
   else
   {
    if(!user.accessToken) return
    try{
     const response = await get(LEXCORE_URL+`/store/products`,{
      headers:{
        Authorization: `Bearer ${user.accessToken}`
      }
    });
     if(response) 
     {
      const data = response.data.map(d => d);
      setProducts([...data]);
      setIsLoading(false);
     }
    }catch(e){
     setIsLoading(false);
     setError(true);
     setOpenErrorLoading(true);
    }
    
   }
  }

  //Vista de la tienda (Store)
  return (
    <div className='flex w-full flex-col'>
      <div className="flex justify-between mb-8 flex-wrap w-full">
        {false && <NavbarProducts servicios={tipo_productos} />}
        <Search query={"l"}
          accion={searchQuery}
          placeholder="Describe un producto para buscar..."
        />
      </div>
      <ProductGrid 
        productos={query?filteredProducts:products} 
        isLoading={isLoading}
        error={error} 
        user={user}
        onSelectedProduct={obtenerDatosProductoSeleccionado}
        refetchProducts={refetchProducts}
        
      />
      
      <ModalConfirmation 
        pregunta={"¿Quieres comprar este producto?"}
        textoConfirmar={"Comprar"}
        textoCancelar={"Cancelar"}
        isOpen={openConfirm}
        loading={loadingStripe} 
        item={selectedProduct}
        onClose={cerrarModalConfirmacion} 
      />

      <ModalSuccess
        mensaje={"¡Compra exitosa!"}
        mensajeAdicional={"Se ha procesado la compra del producto exitosamente."}
        textoBoton={"Regresar a la tienda"}
        isOpen={openSuccess}
        onClose={()=>{setOpenSuccess(false)}}
      />

      <ModalError
        isOpen={openErrorLoading}
        accion={refetchProducts}
        onClose={()=>{setOpenErrorLoading(false)}}
        tituloMensaje="Ooops !"
        mensaje={"Ha ocurrido un problema al intentar cargar los productos."}
        textoReintentar={"Volver a intentar"}
      />

      <ModalError
       isOpen={openErrorStripe}
       accion={obtenerDatosProductoSeleccionado}
       onClose={()=>{setOpenErrorStripe(false)}}

       tituloMensaje="Ooops !"
       mensaje="Ha ocurrido un problema al intentar comprar el producto."
       textoReintentar="Volver a intentar"       
      />
      

    </div>
  )
}

export default LexcoreStore;