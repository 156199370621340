
import { useEffect, useState } from 'react';
import { MessageLeyes } from './messageLeyes';
import { MessageRelacionados } from './messageRelacionados';
import { MessageSimilares } from './messageSimilares';
import { MessageScjn } from './messageScjn';
import Tour from '../../useTour';

export const Message = (props) => {

    const { user, id_chat, message, abrirFuente, userPermission } = props;

    const [leyes, setLeyes] = useState([]);
    const [relacionados, setRelacionados] = useState([]);
    const [similares, setSimilares] = useState([]);

    const obtenerArticulosSimilares = (listaLeyes) => {

        let articulosSimilares = [];
        for (let ley of listaLeyes) {
            if (ley.Relacionados_Articulos && ley.Relacionados_Articulos.length > 0) {
                let articuloSimilar = {}
                articuloSimilar["ley"] = ley.Contenido_Articulo;
                articuloSimilar["articulos"] = ley.Relacionados_Articulos.map((articulo, index) => (
                    {
                        "articulo": articulo,
                        "resumen": ley.Relacionados_Contenido[index],
                        "pagina": ley.Relacionados_Paginas[index] + 1,
                        "url": ley.url || "https://www.diputados.gob.mx/LeyesBiblio/pdf/CPEUM.pdf",
                        "ley": ley.Ley.toUpperCase()
                    }));
                articulosSimilares.push(articuloSimilar);
            }
        }
        setSimilares(articulosSimilares);
    }

    const obtenerArticulosRelacionados = (listaLeyes) => {

        let articulosRelacionados = [];
        for (let ley of listaLeyes) {
            if (ley.Cluster_Articulos.length >= 1) {

                let articuloRelacionado = {}
                articuloRelacionado["ley"] = ley.Contenido_Articulo;
                articuloRelacionado["resumen"] = ley.Cluster_Resumen;
                articuloRelacionado["articulos"] = ley.Cluster_Articulos.map((articulo, index) => (
                    {
                        "articulo": articulo,
                        "pagina": ley.Cluster_Paginas[index] +1,
                        "url": ley.url || "https://www.diputados.gob.mx/LeyesBiblio/pdf/CPEUM.pdf",
                        "ley": ley.Ley.toUpperCase()
                    }));

                articulosRelacionados.push(articuloRelacionado);
            }
        }

        setRelacionados(articulosRelacionados);
    }


    const obtenerLeyes = (listaLeyes) => {
        const leyesMensaje = listaLeyes.map(ley => ({
            "articulo": ley.Contenido_Articulo,
            "resumen": ley.Contenido_Resumen,
            "pagina": ley.Contenido_Pagina +1,
            "url": ley.url || "https://www.diputados.gob.mx/LeyesBiblio/pdf/CPEUM.pdf",
            "ley": ley.Ley.toUpperCase()
        }));
        setLeyes(leyesMensaje);
    }

    useEffect(() => {
        if (message && 'leyes' in message) {
            const listaLeyes = Object.values(message.leyes);
            obtenerLeyes(listaLeyes);
            obtenerArticulosRelacionados(listaLeyes);
            obtenerArticulosSimilares(listaLeyes);
        }
    }, [id_chat, message]);

    const steps =[
        {
            title: 'BIENVENIDOO AL CHAT!',
            content: 'DEMOS UNA VUELTA!', 
            locale: { skip: <strong aria-label="skip">OMITIR</strong> },
            placement: "center",
            target: "body",
        },

        {
            content: "Aqui podras ver la pregunta que realizaste",
            target: ".STEP-VIEW-QUESTION-CHAT"
        },

        {
            content: "Aqui podras ver la respuesta de la pregunta que realizaste",
            target: ".STEP-VIEW-RESPONSE-CHAT"
        },

        {
          content: "Aqui podras consultar los articulos que respaldan tu pregunta",
          target: ".STEP-ARTICURLOS-RESPALDO-CHAT"
        },
      
        {
          content: "Aqui podras consultar los articulos a los cuales se relcionan con tu pregunta",
          target: ".STEP-ARTICURLOS-RELACIONADOS-CHAT"
        },
        
        {
          content: "Aqui podras consultar los articulos que son similares con tu pregunta",
          target: ".STEP-ARTICURLOS-SIMILARES-CHAT"
        }
      ]

    return (
        <section className="flex flex-col gap-5 border-b-5 border-blue-30 border-solid my-10">
            <p className="bg-primarycolorcard p-2 font-bold rounded text-white STEP-VIEW-QUESTION-CHAT">Pregunta: {message.pregunta} </p>
            <p className="bg-terciarycolorcard p-2 rounded text-secondarytext STEP-VIEW-RESPONSE-CHAT">Respuesta: {message.contenido} </p>
            {userPermission === 3 && (
                <MessageScjn abrirFuente={abrirFuente} query={message.pregunta} message={message.contenido}/>
            )}
            <MessageLeyes user={user} leyes={leyes} abrirFuente={abrirFuente} />
            <MessageRelacionados user={user} listaRelacionados={relacionados} abrirFuente={abrirFuente} />
            <MessageSimilares user={user} listaSimilares={similares} abrirFuente={abrirFuente} />
            <Tour steps={steps} localStorageKey="key-message-01"/>

        </section>)
}