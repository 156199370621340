import { Link } from "react-router-dom";

const Terminos = () => {
    return (
        <div className="flex w-full h-screen items-center flex-col p-20">
            <Link to="/Login" className={`flex w-auto items-center px-6 py-2 mt-4 text-white hover:bg-slate-500  bg-opacity-25 flex-col w-full rounded-md`}>
                <p className="text-left text-gray-200"> Regresar </p>
            </Link>
          <div className="text-4xl">
            <span className="text-red-400 font-semibold">Terminos y Condiciones</span>
            <div className="mt-5">
                <p className="text-base flex text-gray-200 font-semibold text-justify mt-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                <p className="text-base flex text-gray-200 font-semibold text-justify mt-5">Vestibulum rhoncus est pellentesque elit ullamcorper dignissim. Etiam erat velit, scelerisque in dictum non, consectetur a erat. Amet nisl purus in mollis nunc sed id. Ut etiam sit amet nisl purus in mollis nunc. Sit amet nulla facilisi morbi tempus iaculis urna id volutpat lacus.</p>
                <p className="text-base flex text-gray-200 font-semibold text-justify mt-5">Fusce id velit ut tortor pretium viverra suspendisse potenti. Vel orci porta non pulvinar neque laoreet suspendisse interdum consectetur. Tellus in metus vulputate eu scelerisque felis. Suspendisse interdum consectetur libero id faucibus nisl tincidunt eget. Ultrices eros in cursus turpis massa tincidunt dui ut ornare lectus.</p>
                <p className="text-base flex text-gray-200 font-semibold text-justify mt-5">Curabitur gravida arcu ac tortor dignissim convallis. Nisl condimentum id venenatis a condimentum vitae sapien pellentesque habitant. Sed id semper risus in hendrerit gravida rutrum. Semper viverra nam libero justo laoreet sit amet cursus sit amet. Quam elementum pulvinar etiam non quam lacus suspendisse faucibus.</p>
                <p className="text-base flex text-gray-200 font-semibold text-justify mt-5">Pulvinar elementum integer enim neque volutpat ac tincidunt vitae. Viverra accumsan in nisl nisi scelerisque eu ultrices vitae auctor. Nibh venenatis cras sed felis eget velit aliquet sagittis id. Nec tincidunt praesent semper feugiat nibh sed pulvinar proin gravida. At urna condimentum mattis pellentesque id nibh tortor id aliquet.</p>
            </div>
          </div>
        </div>
    );
}

export default Terminos;