import React, { useState } from 'react';
import { update } from '../../config/axios';
import Selection from '../selection';
import { analytics } from '../../config/firebase';

export const UserRow = (props) => {

  const { nombre, fecha, uid, dbRole, dbStatus, user, photo } = props;
  const rolesOpcion = [{ label: "Usuario", value: "User" }, { label: "Admin", value: "admin" }];
  const statusOpcion = [{ label: "Activo", value: true }, { label: "Inactivo", value: false }]

  const [role, setRole] = useState(dbRole);
  const [status, setStatus] = useState(dbStatus);

  const changeData = async () => {
    if (role !== undefined && status !== undefined) {
      analytics('actualizar_usuario', {nombre: uid, role, status, user});
      await update('', {
        uid, role, status
      }, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`
        }
      });
      alert('Usuario Modificado');
    }
    else {
      alert('Se esperaba rol y status');
    }
  }

  return (
    <div className='flex flex-row gap-3 w-full justify-between overflow-y-visible'>
      <span className="w-28">
      <img alt="avatar" src={photo || "/external/avatar.png"} className="w-full" />
      </span>
      <span className="w-28">
        <span className="">{nombre}</span>
      </span>
      <span className="w-28">
        <span className="">{fecha}</span>
      </span>
      <span className="w-28">
        <span className="">{role ? role : 'Sin Autorizar'}</span>
      </span>
      <span className="w-28">
        <span className="">{status ? 'Activo' : 'Inactivo'}</span>
      </span>
      <Selection opciones={rolesOpcion} selected={rolesOpcion[rolesOpcion.findIndex(i => i.value === role)]} action={(e)=> setRole(e.value)} />
      <Selection opciones={statusOpcion} selected={statusOpcion[statusOpcion.findIndex(i => i.value === status)]} action={(e)=>setStatus(e.value)} />
      <span className="w-28">
        <button onClick={changeData} >Cambiar</button>
      </span>
    </div>
  )
}

export const UserHeader = () => {
  return (
    <div className='flex flex-row gap-3 w-full justify-between'>
      <span className="w-28">
        <span className="">Foto</span>
      </span>
      <span className="w-28">
        <span className="">Nombre</span>
      </span>
      <span className="w-28">
        <span className="">Creado</span>
      </span>
      <span className="w-28">
        <span className="">Rol Actual</span>
      </span>
      <span className="w-28">
        <span className="">Status Actual</span>
      </span>
      <span className="w-28">
        <span className="">Roles</span>
      </span>
      <span className="w-28">
        <span className="">Status</span>
      </span>
      <span className="w-28">
        <span className="">Confirmar</span>
      </span>
    </div>
  )
};