import { useState } from "react";
import {Modal} from "../modal/modal";

const ModalCreateUser = (props) => {
  const { active, close, addUser } = props;

  const newUserTemplate = {
    "nombre": "",
    "apellido": "",
    "correo": "",
    "confirmar_correo": "",
    "uid": "1234567890",
    "creado": 1000000000
  }

  const [newUser, setNewUser] = useState({ ...newUserTemplate });

  const crearUsuario = (e) => {
    e.preventDefault();
    if (
      newUser.nombre.length > 0 &&
      newUser.apellido.length > 0 &&
      newUser.correo.length > 0 &&
      newUser.confirmar_correo.length > 0 &&
      newUser.correo === newUser.confirmar_correo
    ) {
      addUser(newUser);
      setNewUser(newUserTemplate);
      alert('Enviado');
      close();
    }
    else {
      alert('Datos incorrectos');
    }
  };

  return (
    <Modal active={active} action={close}>
      <div className="flex flex-col gap-5">
        <span className="text-xl">Crea un usuario</span>
        <form className="flex flex-col gap-5" onSubmit={crearUsuario}>
          <span className="grid grid-cols-2 wrap gap-3 justify-evenly">
            Nombre:
            <input type="text" placeholder="Nombre" value={newUser.nombre} onChange={(e) => setNewUser({ ...newUser, nombre: e.target.value })} />
          </span>
          <span className="grid grid-cols-2 wrap gap-3 justify-evenly">
            Apellido:
            <input type="text" placeholder="Apellido" value={newUser.apellido} onChange={(e) => setNewUser({ ...newUser, apellido: e.target.value })} />
          </span>
          <span className="grid grid-cols-2 wrap gap-3 justify-evenly">
            Correo:
            <input required type="email" placeholder="Correo" value={newUser.correo} onChange={(e) => setNewUser({ ...newUser, correo: e.target.value })} />
          </span>
          <span className="grid grid-cols-2 wrap gap-3 justify-evenly">
            Confirma correo:
            <input required type="email" placeholder="Confirma correo" value={newUser.confirmar_correo} onChange={(e) => setNewUser({ ...newUser, confirmar_correo: e.target.value })} />
          </span>
          <input type="submit" value="Enviar" className=" cursor-pointer" />
        </form>
      </div>
    </Modal>
  )
};

export default ModalCreateUser;