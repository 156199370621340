import React, { useState, useEffect } from 'react';
import { PlanList } from "../components/upgrade/planList";
import { getFirestore, getQueryWhere } from '../config/firestore';
import { useParams } from 'react-router-dom';
import { ModalConfirmation } from '../components/modalConfirmation';
import { ModalSuccess } from '../components/modalSuccess';
import { ModalError } from '../components/modalError';
import { get } from '../config/axios';
import { analytics } from '../config/firebase';
import { BASE_URL } from '../constants';
import Tour from '../useTour';

const Subscription = (props) => {

  const estadoCompra = new URLSearchParams(window.location.search).get('estado_compra');
  const mostrarModalSuccess = estadoCompra === "finalizada" ? true : false;

  const { subscriptionName } = useParams();
  const { user, updateToken } = props;
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState({"id":null});
  const [isLoading, setIsLoading] = useState(true);
  const [loadingStripe, setLoadingStripe] = useState(false);
  const [error, setError] = useState(false);
  const [refetch, setRefetch] = useState(false);


  const [openConfirm, setOpenConfirm] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(mostrarModalSuccess);
  const [openErrorLoading, setOpenErrorLoading] = useState(false);
  const [openErrorStripe, setOpenErrorStripe] = useState(false);

 
  
  const features = ["Feature 1", "Feature 2", "Feature 3", "Feature 4"]
  
  useEffect(() => {
    if(user.uid && estadoCompra==="finalizada"){
      analytics("suscripcion_adquirida", {user});
      updateToken(user)
    }
    if (subscriptions.length === 0 && !subscriptionName) getSubscriptions();
    if (subscriptionName && subscriptions.length === 0) {
      const obtenerSuscripcion = async () => {
        const { docs } = await getQueryWhere('products', ["name", subscriptionName]);
        if (docs) {
          const data = docs.map(d => d.data());
          setSubscriptions(data);
        }
      }
      obtenerSuscripcion()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, subscriptions, refetch, estadoCompra]);

  const cerrarModalConfirmacion = () => {
    setOpenConfirm(false);
    setSelectedSubscription(null)
  }

  const obtenerDatosSuscripcionSeleccionada = async (id) => {
    analytics('intencion_de_comprar_suscripcion', {nombre: id, user});
    setLoadingStripe(true);
    setSelectedSubscription({"id":id});
    setOpenConfirm(true);
    try
    {  
     const {data} = await get(BASE_URL+`/store/product-info?product=${id}`, 
     {
      headers: 
      {
       Authorization: `Bearer ${user.accessToken}`
      }
     });
     if(data) 
     {
      setSelectedSubscription(data);
      setLoadingStripe(false); 
     }
    }catch(e)
    {
     setOpenConfirm(false);
     setLoadingStripe(false);
     setOpenErrorStripe(true);
    }
  }


  const refetchSubscriptions = () => {
   setIsLoading(true);
   setError(false)
   setOpenErrorLoading(false)
   setRefetch(true);
  }


  const getSubscriptions = async () => {
    setIsLoading(true);
    setRefetch(false);
    if(!navigator.onLine)
    {
     setIsLoading(false);
     setError(true);
     setOpenErrorLoading(true);
    }
    else
    {
     try
     {
      const response = await getFirestore('products', 'created', subscriptions.length > 0 ? subscriptions.at(-1).created : '', 5);
      if (response) 
      {
       const data = response.docs.map(d => d.data());
       const suscripciones = data.filter( producto => producto.tag==="subscription")
       const suscripcionesConFeatures = suscripciones.map( suscripcion => ({"features":features,...suscripcion})) //Varible temporal
       setSubscriptions([...subscriptions, ...suscripcionesConFeatures]);
       setIsLoading(false);      
      }
     }catch(e)
     {
      setIsLoading(false);
      setError(true);
      setOpenErrorLoading(true);
     }  
    }
  }

  const steps = [
    {
      content: <h2>BIENVENIDO A LA COMPRA Y/O ACTUALIZACION DE TU PLAN!</h2>,
      locale: { skip: <strong aria-label="skip">OMITIR</strong> },
      placement: "center",
      target: "body",
    },
  ];

  return (
    <div className='flex w-full flex-col px-12 py-5'>
      <div className="text-4xl my-10">
        <span className="text-gray-200 font-semibold">Actualizar Plan</span>
      </div>

      <PlanList 
        planes={subscriptions}
        isLoading={isLoading}
        error={error}
        onSelectedSubscription={obtenerDatosSuscripcionSeleccionada}
        refetchSubscriptions={refetchSubscriptions}
      />

      <ModalConfirmation
        pregunta="¿Quieres comprar esta suscripcion?"
        textoConfirmar="Comprar"
        textoCancelar="Cancelar compra"

        item={selectedSubscription}
        isOpen={openConfirm}
        loading={loadingStripe}
        onClose={cerrarModalConfirmacion}
      />

      <ModalSuccess
        mensaje={"¡Compra exitosa!"}
        mensajeAdicional={"Se ha procesado la compra de la suscripcion exitosamente."}
        textoBoton={"Regresar a los planes"}
        isOpen={openSuccess}
        onClose={()=>{setOpenSuccess(false)}}
       
      />


      <ModalError
        isOpen={openErrorLoading}
        accion={refetchSubscriptions}
        onClose={()=>{setOpenErrorLoading(false)}}
        tituloMensaje="Ooops !"
        mensaje={"Ha ocurrido un problema al intentar cargar las suscripciones disponibles en la aplicación."}
        textoReintentar={"Volver a intentar"}
      />

      <ModalError
       isOpen={openErrorStripe}
       accion={obtenerDatosSuscripcionSeleccionada}
       onClose={()=>{setOpenErrorStripe(false)}}

       tituloMensaje="Ooops !"
       mensaje="Ha ocurrido un problema al intentar comprar la suscripción."
       textoReintentar="Volver a intentar"       
      />

      <Tour steps={steps} localStorageKey="KEY-SUBSCRIPTION" />
    </div>
  )
}

export default Subscription;