import { SCJN } from "./scjn";

export const Source = (props) => {

  const { user, fuente, cerrarFuente } = props;

  if (!fuente.isOpen) return;

  return (
    <article className="flex-1 fixed z-50 w-full h-screen md:relative">
      <button onClick={cerrarFuente} className="bg-secondarycolorback text-white rounded p-2 mt-5 absolute">Cerrar</button>
      {!fuente.scjn ? (
        <iframe
        key={fuente.pagina}
        title="Documento legal"
        src={`${fuente.url}#page=${fuente.pagina}`}
        className=" w-full h-full p-2 py-5"
        target="_blank"
      />
      ):(
        <SCJN user={user} fuente={fuente.query} answer={fuente.answer} target={fuente.target} />
      )}
    </article>)
}