

export const messageCluster =
{
    "contenido": "Un extranjero puede casarse en M\u00e9xico siempre y cuando cumpla con los requisitos establecidos en la ley civil federal. Esto incluye tener una visa v\u00e1lida y cumplir con los plazos y procedimientos establecidos por el Juez del Registro Civil. Si tienes m\u00e1s dudas sobre este tema, te recomiendo que consultes con un abogado experto en leyes mexicanas.",
    "leyes": {
        "0": {
            "Cluster_Articulos": [],
            "Cluster_Paginas": [],
            "Cluster_Resumen": null,
            "Contenido_Articulo": "Art\u00edculo 1327.",
            "Contenido_Pagina": 136,
            "Contenido_Resumen": "Los extranjeros y las personas morales, son capaces de adquirir bienes por testamento o por intestado pero su capacidad tiene las limitaciones establecidas en la Constituci\u00f3n Pol\u00edtica de los Estados Unidos Mexicanos y en las respectivas leyes reglamentarias de los art\u00edculos constitucionales. Trat\u00e1ndose de extranjeros, se observar\u00e1 tambi\u00e9n lo dispuesto en el art\u00edculo siguiente.",
            "Ley": "ley civil federal",
            "Relacionados_Articulos": [],
            "Relacionados_Contenido": [],
            "Relacionados_Paginas": []
        },
        "1": {
            "Cluster_Articulos": [],
            "Cluster_Paginas": [],
            "Cluster_Resumen": null,
            "Contenido_Articulo": "Art\u00edculo 101.",
            "Contenido_Pagina": 15,
            "Contenido_Resumen": "El matrimonio se celebrar\u00e1 dentro de los ocho d\u00edas siguientes, en el lugar, d\u00eda y hora que se\u00f1ale el Juez del Registro Civil. Art. reforma do DOF 14-03-1973",
            "Ley": "ley civil federal",
            "Relacionados_Articulos": [],
            "Relacionados_Contenido": [],
            "Relacionados_Paginas": []
        },
        "2": {
            "Cluster_Articulos": [
                "Art\u00edculo 732.",
                "Art\u00edculo 736.",
                "Art\u00edculo 734.",
                "Art\u00edculo 738.",
                "Art\u00edculo 1637.",
                "Art\u00edculo 737.",
                "Art\u00edculo 735.",
                "Art\u00edculo 2926.",
                "Art\u00edculo 2700.",
                "Art\u00edculo 723.",
                "Art\u00edculo 838.",
                "Art\u00edculo 740.",
                "Art\u00edculo 2917.",
                "Art\u00edculo 743.",
                "Art\u00edculo 725.",
                "Art\u00edculo 731.",
                "Art\u00edculo 2320.",
                "Art\u00edculo 733.",
                "Art\u00edculo 744.",
                "Art\u00edculo 27.",
                "Art\u00edculo 2317.",
                "Art\u00edculo 741.",
                "Art\u00edculo 773.",
                "Art\u00edcul o 730."
            ],
            "Cluster_Paginas": [
                85,
                86,
                85,
                86,
                162,
                86,
                85,
                280,
                259,
                84,
                96,
                86,
                279,
                87,
                84,
                85,
                223,
                85,
                87,
                5,
                222,
                86,
                90,
                85
            ],
            "Cluster_Resumen": "El texto se centra en el patrimonio de familia, incluyendo su constituci\u00f3n, los bienes que lo integran, la obligaci\u00f3n de habitar la casa y cultivar la parcela, la expropiaci\u00f3n, la extinci\u00f3n y la adquisici\u00f3n de bienes ra\u00edces por parte de extranjeros y personas morales.",
            "Contenido_Articulo": "Art\u00edculo 2274.",
            "Contenido_Pagina": 217,
            "Contenido_Resumen": "Los extranjeros y las personas morales no pueden comprar bienes ra\u00edces, sino sujet\u00e1ndose a lo dispuesto en el art\u00edculo 27 de la Constituci\u00f3n Pol\u00edtica de los Estados Unidos Mexicanos, y en sus leyes reglam entarias.",
            "Ley": "ley civil federal",
            "Relacionados_Articulos": [
                "Art\u00edculo 773.",
            ],
            "Relacionados_Contenido": [
                "Los extranjeros y las personas morales para adquirir la propiedad de bienes inmuebles, observar\u00e1n lo dispuesto en el art\u00edculo 27 de la Constituci\u00f3n de los Estados Unidos Mexicanos y sus leyes reglamentarias.",
            ],
            "Relacionados_Paginas": [
                90,
            ]
        },
        "3": {
            "Cluster_Articulos": [],
            "Cluster_Paginas": [],
            "Cluster_Resumen": null,
            "Contenido_Articulo": "Art\u00edculo 294.",
            "Contenido_Pagina": 38,
            "Contenido_Resumen": "El parentesco de afini dad es el que se contrae por el matrimonio, entre el var\u00f3n y los parientes de la mujer, y entre la mujer y los parientes del var\u00f3n.",
            "Ley": "ley civil federal",
            "Relacionados_Articulos": [],
            "Relacionados_Contenido": [],
            "Relacionados_Paginas": []
        },
        "4": {
            "Cluster_Articulos": [
                "Art\u00edculo 1915.",
                "Art\u00edculo 2116.",
                "Art\u00edculo 474.",
                "Art\u00edculo 444.",
                "Art\u00edculo 323.",
                "Art\u00edculo 1913.",
                "Art\u00edculo 1340.",
                "Art\u00edculo 620.",
                "Art\u00edculo 277.",
                "Art\u00edculo 56 .",
                "Art\u00edculo \u00danico.",
                "Art\u00edculo 1320.",
                "Art\u00edculo 267.",
                "Art\u00edculo 750.",
                "Art\u00edculo \u00danico.",
                "Art\u00edculo 282.",
                "Art\u00edculo 423.",
                "Art\u00edculo 1318.",
                "Art\u00edculo 164.",
                "Art\u00edculo 1834.",
                "Art\u00edculo 47.",
                "Art\u00edculo 577.",
                "Art\u00edculo 89.",
                "Art\u00edculo 258.",
                "Art\u00edculo 60.",
                "Art\u00edculo 1661.",
                "Art\u00edculo 2647.",
                "Art\u00edculo \u00danico.",
                "Art\u00edculo 44.",
                "Art\u00edculo 58.",
                "Art\u00edculo 1316.",
                "Art\u00edculo 391.",
                "Art\u00edculo 1500.",
                "Art\u00edculo Segundo.",
                "Art\u00edculo 1549.",
                "Art\u00edculo 29.",
                "Art\u00edculo 18.",
                "Art\u00edculo 10.",
                "Art\u00edculo 31.",
                "Art\u00edculo 1916.",
                "Art\u00edculo 457.",
                "Art\u00edculo 279.",
                "Art\u00edculo 416.",
                "Art\u00edculo 2318.",
                "Art\u00edculo 1934.",
                "Art\u00edculo \u00danico.",
                "Art\u00edculo 323 bis.",
                "Art\u00edculo 168.",
                "Art\u00edculo 94.",
                "Art\u00edculo 76.",
                "Art\u00edculo Unico.",
                "Art\u00edculo 283.",
                "Art\u00edculo \u00danico.",
                "Art\u00edculo \u00danico.",
                "Art\u00edculo 69.",
                "Art\u00edculo 1322.",
                "Art\u00edculo Segundo."
            ],
            "Cluster_Paginas": [
                186,
                205,
                59,
                55,
                40,
                186,
                137,
                74,
                35,
                9,
                358,
                136,
                32,
                88,
                358,
                36,
                53,
                136,
                23,
                179,
                8,
                70,
                13,
                31,
                10,
                164,
                254,
                358,
                8,
                10,
                135,
                49,
                149,
                367,
                153,
                6,
                4,
                3,
                6,
                186,
                57,
                35,
                52,
                222,
                189,
                358,
                41,
                23,
                14,
                12,
                345,
                36,
                358,
                358,
                11,
                136,
                367
            ],
            "Cluster_Resumen": "El texto abarca una amplia gama de temas del derecho civil, incluyendo el da\u00f1o moral, la patria potestad, el divorcio, el matrimonio, la tutela, la incapacidad, la herencia y los actos jur\u00eddicos.",
            "Contenido_Articulo": "Art\u00edculo 102.",
            "Contenido_Pagina": 15,
            "Contenido_Resumen": "En el lugar, d\u00eda y hora designados para la celebraci\u00f3n del matrimonio deber\u00e1n estar presentes, ante el Juez del Registro Civil, los pretendientes o su apoderado especial constituido en la forma prevenida en el art\u00edculo 44 y dos testigos por cada uno de ellos, que acrediten su identidad. Acto continuo, el Juez del Registro Civil leer\u00e1 en voz alta la solicitud de matrimonio, los documentos que con ella se hayan presentado y las diligencias practicadas, e interrogar\u00e1 a los te stigos acerca de si los pretendientes son las mismas personas a que se refiere la solicitud. En caso afirmativo, preguntar\u00e1 a cada uno de los pretendientes si es su voluntad unirse en matrimonio, y si est\u00e1n conformes, los declarar\u00e1 unidos en nombre de la l ey y de la sociedad. Art. reformado DOF 14-03-1973",
            "Ley": "ley civil federal",
            "Relacionados_Articulos": [],
            "Relacionados_Contenido": [],
            "Relacionados_Paginas": []
        }
    },
    "pregunta": "como se puede casar un extranjero?",
    "timestamp_consulta": 1724788251,
    "tokens_contexto": 1395,
    "tokens_pregunta": 9,
    "tokens_respuesta": 87
}