import { analytics, FirebaseApp } from "../config/firebase";
import * as firebaseui from "firebaseui";
import React, { Fragment, useEffect } from 'react';
import "firebaseui/dist/firebaseui.css";
import { post } from "../config/axios";
import { BASE_URL } from "../constants";
import { Link } from "react-router-dom";


const Login = (props) => {
  const { user, navigate } = props;

  useEffect(() => {
    if (!user || !user.uid) {
      const ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(FirebaseApp.auth());
      ui.start("#firebase-auth-container", {
        signInOptions: [
          FirebaseApp.firebase.auth.EmailAuthProvider.PROVIDER_ID,
          FirebaseApp.firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        signInFlow: "popup",
        callbacks: {
          signInSuccessWithAuthResult: async (authResult) => {
            const loggedUser = authResult.user;
            if (authResult.additionalUserInfo.isNewUser) {
              const { data } = await post(BASE_URL + '/user/create', {
                uid: loggedUser.uid,
                displayName: loggedUser.displayName,
                email: loggedUser.email,
                photoURL: loggedUser.photoURL
              }, {
                headers: {
                  Authorization: `Bearer ${loggedUser.multiFactor.user.accessToken}`
                }
              })
              if (data) {
                analytics("usuario_inicia_sesion", { user })
                navigate('/')
                return false;
              }
            }

          },
        }
      });
    }
    else {
      navigate('/');
    }
  }, [user, navigate]);

  return (

    <div className="flex flex-wrap w-full md:h-fit bg-white flex-col-reverse md:flex-row">
      {(!user || !user.uid) && (
        <Fragment>

          <div className="flex w-full md:w-1/2 justify-center items-center h-fit md:h-screen">
            <img
              alt="Ellipse11794"
              src="icons/login_icon.svg"
              className="login-ellipse1 max-w-2xl h-auto md:h-auto pt-12 max-h-1/3 flex"
            />
          </div>

          <div className="flex w-full md:w-1/2 h-1/2 md:min-h-screen md:pt-0 md:p-5 pt-48 px-10 text-center">
            <div className="flex flex-col items-center justify-center w-full text-black">
              <span className="login-text text-4xl mb-2">Iniciar sesion</span>
              <div className="flex flex-row gap-3 items-center">
                <span className="login-text4 text-xl mb-1">A-Dike - Asistente Legal Digital</span>
              </div>
              <span className="login-text2 mt-12 text-2xl ">Ingresa con tu correo o cuenta de Gmail</span>
              <div id="firebase-auth-container" />
              <div className="text-xs text-gray-500">*Al usar nuestro servicio aceptas nuestros
                <Link to="/terminos" className="underline hover:text-blue-500"> Terminos y Condiciones</Link> y nuestro <Link to="/politicas" className="underline hover:text-blue-500"> Política de privacidad </Link></div>
            </div>
          </div>

        </Fragment>
      )}
    </div>

  )
};

export default Login;