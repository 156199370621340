import { Fragment, useState } from "react";

export const SCJNMessage = (props) => {

  const { options, idDetalle } = props;

  const [isLoading, setIsLoading] = useState(false); 
  const [response, setResponse] = useState({
    "index": 0,
    "data": {}
  })

  const getDetailSCJN = async (id, index) => {
    setIsLoading(true);
    setResponse({data:{}, index})
    const data = await idDetalle(id);
    if (data) {
      setResponse({index, data});
      setIsLoading(false);
    }
  }

  return (
    <section className="flex flex-col gap-3">
      {
        options.contenido.map((option, index) => (
          <Fragment>
            <button
              className="bg-terciarycolorcard p-2 rounded text-secondarytext text-m text-left"
              onClick={() => getDetailSCJN(option.id, index)}
              title={`Abrir Detalles`}
            >
              {option.id}: {option.nombre}
            </button>
            {response.index === index && isLoading && (
              <span className="text-secondarytext">Cargando...</span>
            )}
            {response && 'contenido' in response.data && response.index === index && (
              <button 
                className="bg-secondarycolorback p-1 rounded text-secondarytext w-full text-m text-left mb-5"
                title={`Abrir resolución`}
              >
                <a href={response.data.referencia} target="_blank" rel="noreferrer">{response.data.contenido}</a>
              </button>
            )}
          </Fragment>
        ))}
    </section>
  );
};