import { FeatureList} from "./featureList";


export const PlanCard = (props) => {

    const { id, nombre, descripcion, precio, features, onSelectedSubscription} = props;

    return(
        <article className="bg-primarycolorcard rounded p-3 flex flex-col max-h-full w-60 flex-wrap justify-between gap-3">

         <section className="flex flex-col gap-2">
         <header className=" w-20 bg-gray-200 rounded-full h-20" >
         </header>

         <h2 className="text-primarycolortext text-2xl">{nombre}</h2>
         <p className="text-primarycolortext">{descripcion}</p>
         <p className="text-secondarycolortext">$ {precio} MXN / 
            <span className="">Mensual</span>
         </p>
         

         <FeatureList features={features} />
         </section>

         <footer className="">
            <button onClick={()=>{onSelectedSubscription(id)}} className=" text-white bg-green-500 p-1 rounded w-full" >Get started</button>
         </footer>

        </article>
    )
}