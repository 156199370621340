import { Link } from "react-router-dom";

const Aviso = () => {
    return (
        <div className="flex w-full h-auto items-center flex-col p-20">

          <div className="text-4xl">
            <span className="text-red-400 font-semibold">Política de Privacidad</span>
            { /*Seccion Introduccion */}
            <div className="mt-5">
                <span className="text-2xl text-red-300 font-semibold">Introducción</span>
                <p className="text-base flex text-gray-200 font-semibold text-justify mt-5">En Adike, tu privacidad es fundamental para nosotros. Reconocemos la
                    importancia de proteger tus datos personales y garantizar su manejo
                    responsable, ético y conforme a la legislación vigente. Este compromiso es el
                    núcleo de nuestras operaciones y se refleja en cada uno de nuestros servicios.
                    Hemos diseñado esta Política de Privacidad para explicarte de manera clara y
                    transparente cómo recopilamos, utilizamos, almacenamos y protegemos tu
                    información personal cuando interactúas con nuestra plataforma y servicios.
                </p>
                <p className="text-base flex text-gray-200 font-semibold text-justify mt-5">
                    Nuestra empresa opera bajo los más altos estándares legales internacionales en
                    materia de protección de datos. Nos adherimos al Reglamento General de
                    Protección de Datos (RGPD) de la Unión Europea, la Ley de Privacidad del
                    Consumidor de California (CCPA) de Estados Unidos, la Ley Federal de
                    Protección de Datos Personales en Posesión de los Particulares (LFPDPPP) de
                    México, y otras normativas relevantes en los países donde ofrecemos nuestros
                    servicios. Esta política también refleja nuestro compromiso con la protección de
                    los derechos de los usuarios, incluyendo el acceso, la rectificación, la
                    cancelación y la oposición al uso de sus datos personales.
                </p>
                <p className="text-base flex text-gray-200 font-semibold text-justify mt-5">
                    Al utilizar Adike, aceptas las prácticas descritas en esta política y nos confías el
                    manejo de tu información. Queremos que te sientas seguro y respaldado en
                    cada interacción. Nuestra herramienta está diseñada no solo para facilitar tareas
                    legales y académicas, sino también para ofrecer una experiencia tecnológica
                    innovadora que respalde la seguridad de tu información.    
                </p>
                <p className="text-base flex text-gray-200 font-semibold text-justify mt-5">
                    Te invitamos a leer esta Política de Privacidad en su totalidad para entender a
                    detalle nuestras prácticas. Si tienes preguntas o necesitas aclaraciones, nuestro
                    equipo está siempre disponible para apoyarte y garantizar que tu experiencia
                    con Adike sea excepcional.
                </p>
                <p className="text-base flex text-gray-200 font-semibold text-justify mt-5">
                    Al utilizar nuestros servicios, aceptas los términos descritos en esta Política de
                    Privacidad.
                </p>
            </div>
            { /*Seccion Datos */}
            <div className="mt-5">
                <span className="text-2xl text-red-300 font-semibold">¿Qué datos personales recogemos?</span>
                <p className="text-base flex text-gray-200 font-semibold text-justify mt-5">
                    I) Información de carácter personal.
                </p>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    Cuando interactúas con Adike, podemos recopilar diferentes tipos de
                    información personal, entre ellos:
                    <p className="py-1 px-3">
                        a) Datos de contacto: Nombre, correo electrónico, teléfono y dirección,
                        solicitadas en la creación de la cuenta.

                    </p>
                    <p className="py-1 px-3">
                        b) Datos de autenticación: Credenciales de inicio de sesión y contraseñas,
                        utilizadas en Adike.
                    </p>
                    <p className="py-1 px-3">
                        c) Información de uso: Datos sobre cómo utilizas nuestros servicios,
                        incluyendo direcciones IP, tipo de dispositivo, navegador y páginas
                        visitadas dentro de Adike.

                    </p>
                    <p className="py-1 px-3">
                        d) Datos financieros: Información de pago, como detalles de tarjetas de
                        crédito o débito, utilizadas para gestionar tus pagos y servicios
                        proporcionados por Adike.
                    </p>
                </p>
                <p className="text-base flex text-gray-200 font-semibold text-justify mt-5"> II) Información Técnica y de Navegación</p>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    Recopilamos datos técnicos y de navegación para mejorar tu experiencia de usuario y el rendimiento de nuestros servicios:
                    <p className="py-1 px-3">
                        a) Tipo de dispositivo y sistema operativo.
                    </p>
                    <p className="py-1 px-3">
                        b) Navegador utilizado.
                    </p>
                    <p className="py-1 px-3">
                        c) Resolución y configuración de pantalla.
                    </p>
                </p>
                <p className="text-base flex text-gray-200 font-semibold text-justify mt-5">III) Datos generados por el Usuario</p>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    Durante el uso de nuestros servicios, recopilamos y procesamos datos relacionados con tus actividades:
                    <p className="py-1 px-3">
                        a) Textos, videos, títulos y nombres de archivos.
                    </p>
                    <p className="py-1 px-3">
                        b) Nombres de creadores de contenido.
                    </p>
                    <p className="py-1 px-3">
                        c) Fotografías, videografías y archivos de audio.
                    </p>
                </p>
            </div>
            { /*Seccion Uso de los Datos Personales */}
            <div className="mt-5">
                <span className="text-2xl text-red-300 font-semibold">¿Qué uso damos a los datos personales?</span>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    <span className="text-base flex text-gray-200 font-semibold text-justify mt-5">I) Propósito del Tratamiento de Datos</span>
                    Utilizamos tu información personal para los siguientes fines:
                    <p className="py-1 px-3">
                        a) Prestación de servicios: Proveerte acceso y funcionalidades de nuestra herramienta Adike.
                    </p>
                    <p className="py-1 px-3">
                        b) Personalización: Adaptar nuestras funciones a tus necesidades específicas.
                    </p>
                    <p className="py-1 px-3">
                        c) Comunicación: Enviarte notificaciones importantes, actualizaciones o información relevante sobre nuestros servicios.
                    </p>
                    <p className="py-1 px-3">
                        d) Cumplimiento legal: Asegurarnos de cumplir con las leyes aplicables y responder a solicitudes legales.
                    </p>
                    <p className="py-1 px-3">
                        e) Seguridad: Proteger nuestras plataformas contra accesos no autorizados, fraudes y otros riesgos.
                    </p>
                </p>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    <span className="text-base flex text-gray-200 font-semibold text-justify mt-5">II) Procesamiento y Almacenamiento de Datos</span>
                    Toda la información se procesa y almacena en Google Cloud, una plataforma que cumple con los más altos estándares de seguridad y privacidad. Google Cloud no comparte tu información con terceros, y todos los datos se almacenan en servidores seguros.
                </p>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    <span className="text-base flex text-gray-200 font-semibold text-justify mt-5">III) Medidas de Seguridad</span>
                    Implementamos medidas de seguridad avanzadas para proteger tu información:
                    <p className="py-1 px-3">
                        a) Procesamiento de datos mediante contenedores Docker.
                    </p>
                    <p className="py-1 px-3">
                        b) Protección y enmascaramiento de DNS utilizando Cloudflare.
                    </p>
                    <p className="py-1 px-3">
                        c) Aplicación de ciberseguridad y buenas prácticas de protección de datos.
                    </p>
                </p>
            </div>
            { /*Seccion Protección de la Información */}
            <div className="mt-5">
                <span className="text-2xl text-red-300 font-semibold">¿Proteges la información?</span>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    Implementamos medidas técnicas, administrativas y físicas para proteger tus datos contra acceso no autorizado, pérdida, alteración o divulgación indebida. Esto incluye:
                    <p className="py-1 px-3">
                        a) Encriptación: Datos cifrados durante la transmisión y almacenamiento.
                    </p>
                    <p className="py-1 px-3">
                        b) Control de accesos: Sólo el personal autorizado puede acceder a la información personal.
                    </p>
                    <p className="py-1 px-3">
                        c) Auditorías regulares: Revisamos periódicamente nuestros sistemas y procesos para garantizar el cumplimiento de los estándares de seguridad.
                    </p>
                </p>
            </div>
            { /*Seccion Acceso a la Información */}
            <div className="mt-5">
                <span className="text-2xl text-red-300 font-semibold">¿Tengo acceso a la información?</span>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    El acceso a tus datos está restringido a personal autorizado de Consultor-IA, empresa desarrolladora de Adike, incluyendo:
                    <p className="py-1 px-3">
                        a) Personal del área de datos.
                    </p>
                    <p className="py-1 px-3">
                        b) Desarrolladores en tecnologías de la información.
                    </p>
                    <p className="py-1 px-3">
                        c) Directivos de Consultor-IA.
                    </p>
                </p>
            </div>
            { /*Seccion Fundamento Legal para el Tratamiento de Datos */}
            <div className="mt-5">
                <span className="text-2xl text-red-300 font-semibold">¿Y su fundamento legal para el tratamiento de datos?</span>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    Cumplimos con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPPP) de México y sus principios rectores, tales como el principio de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad.
                </p>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    Asimismo, aplicamos lo dispuesto en el artículo 6 de la LFPDPPP, que establece las bases para el tratamiento de datos respecto del Consentimiento, Ejecución de un contrato, Intereses legítimos y el Cumplimiento de la ley.
                </p>
            </div>
            { /*Seccion Cumplimiento con los Derechos del Consumidor */}
            <div className="mt-5">
                <span className="text-2xl text-red-300 font-semibold">¿Cumples con los Derechos del consumidor (CCPA, RGPD y LFPDPPP)?</span>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    Conforme a la LFPDPPP, le informamos que puede ejercer sus derechos ARCO al tratamiento de sus datos personales. Puedes ejercer estos derechos a través de los medios de contacto proporcionados. 
                </p>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    Sin embargo, nos reservamos el derecho de mantener dicha información para propósitos de investigación, desarrollo o por asunto legal que nos obligue a mantenerlos.
                </p>
            </div>
            { /*Seccion Compartir Información */}
            <div className="mt-5">
                <span className="text-2xl text-red-300 font-semibold">¿Compartes mi información?</span>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    Podemos compartir tus datos personales en los siguientes supuestos:
                    <p className="py-1 px-3">
                        a) Proveedores de servicios: Pueden compartirse datos a terceros que ayudan en la operación de nuestros servicios, como procesadores de pagos.
                    </p>
                    <p className="py-1 px-3">
                        b) Cumplimiento legal de instituciones gubernamentales: Para cumplir con la ley o responder a solicitudes legales, de conformidad con la LFPDPPP artículo 37.
                    </p>
                    <p className="py-1 px-3">
                        c) Transferencias empresariales: Si nos fusionamos o somos adquiridos, tus datos pueden ser transferidos como parte de la transacción.
                    </p>
                    <p className="py-1 px-3">
                        d) Transferencia internacional de datos: Si transferimos tus datos fuera del Espacio Económico Europeo (EEE), nos aseguraremos de que estén protegidos por cláusulas contractuales estándar u otros mecanismos conforme a lo establecido en el RGPD (artículo 46), la LFPDPPP (artículo 36) y las políticas de Docker y Cloudflare.
                    </p>
                </p>
            </div>
            { /*Seccion Uso de Cookies */}
            <div className="mt-5">
                <span className="text-2xl text-red-300 font-semibold">Uso de Cookies</span>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    Adike utiliza cookies de sesión de Google, entre otras, para mejorar la funcionalidad de nuestros servicios y facilitar la navegación en nuestro sistema. Estas cookies son esenciales para el funcionamiento del servicio y no recopilan información personal adicional. Estas sirven para lo siguiente:
                    <p className="py-1 px-3">
                        a) Analizar el uso de nuestra plataforma.
                    </p>
                    <p className="py-1 px-3">
                        b) Recordar tus preferencias en nuestro sistema.
                    </p>
                    <p className="py-1 px-3">
                        c) Personalizar nuestro contenido.
                    </p>
                </p>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    Siempre puedes configurar tu navegador para bloquear o eliminar cookies, aunque esto podría afectar ciertas funcionalidades de nuestros servicios.
                </p>
            </div>
            { /*Seccion Uso de Servicios de Terceros */}
            <div className="mt-5">
                <span className="text-2xl text-red-300 font-semibold">¿Usan servicios de terceros?</span>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    Nuestra aplicación puede contener enlaces a sitios web de terceros, como el RSS del Diario de Chihuahua, Wordpress, OpenAI y APIs conectadas. Adike o la empresa desarrolladora no es ni será responsable de las prácticas de privacidad de estos sitios.
                </p>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    Te recomendamos revisar sus políticas de privacidad antes de interactuar con ellos o con nosotros.
                </p>
            </div>
            { /*Seccion Retención de Datos */}
            <div className="mt-5">
                <span className="text-2xl text-red-300 font-semibold">¿Retienes mis datos?</span>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    Conservamos tus datos personales sólo durante el tiempo necesario para cumplir con los fines establecidos o según lo exija la ley, de acuerdo con los artículos 11 y 13 de la LFPDPPP.
                </p>
            </div>
            { /*Seccion Tratamiento de Datos de Infantes */}
            <div className="mt-5">
                <span className="text-2xl text-red-300 font-semibold">Tratamiento de datos de infantes</span>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    Nos acoplamos a las políticas de privacidad infantil (COPPA). Adike no busca recopilar información de menores, y no recopilamos intencionalmente información personal de menores de 13 años, conforme a la Ley de Protección de la Privacidad Infantil en Línea.
                </p>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    Nos deslindamos de cualquier reserva de datos no intencional dentro de nuestras bases de datos.
                </p>
            </div>
            { /*Seccion Contacto */}
            <div className="mt-5">
                <span className="text-2xl text-red-300 font-semibold">Contacto</span>
                <p className="text-base text-gray-200 font-semibold text-justify mt-5">
                    Si tienes alguna pregunta o inquietud sobre esta política de privacidad, sobre cómo manejamos tu información personal, o para ejercer alguno de los derechos ARCO, no dudes en contactarnos a través de los siguientes medios de soporte proporcionados:
                    <p className="py-1 px-3">
                        a) <a href="mailto:contacto@consultor-ia.tech." className="hover:text-blue-500 underline"> Contacto@consultor-ia.tech</a>
                    </p>
                </p>
            </div>

          </div>
          <Link to="/Login" className={`flex items-center px-6 py-2 m-4 text-white hover:bg-slate-500  bg-opacity-25 flex-col w-full rounded-md`}>
                <p className="text-left text-gray-200"> Regresar </p>
            </Link>
        </div>
    );
}

export default Aviso;