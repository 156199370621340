import React, { useState, useEffect } from 'react';
import { get, post } from '../../config/axios';
import { Error } from "../../components/error";
import { LEXCORE_URL } from '../../constants';
import { ModalSuccess } from '../../components/modalSuccess'; 
import { MappingInput } from '../../components/mappingInput';

const LexcoreMappings = (props) => {
  const product = new URLSearchParams(window.location.search).get('product');
  const { user, navigate } = props;
  const [productMappings, setProductMappings] = useState(false);
  const [formValues, setFormValues] = useState(false);
  const [error, setError] = useState(false);
  const [errorSaving, setErrorSaving] = useState(false);
  const [success, setSuccess] = useState(false);

  const getMappings= async () =>{
    try{
      const response = await get(LEXCORE_URL+`/store/product-mappings?product=${product}`,{
        headers:{
          Authorization: `Bearer ${user.accessToken}`
        }
      });
      if (!response.data) {
        throw new Error(response.statusText);
     }
     const transformedData = response.data.map((item) => ({
        productId: item.product_id, // Mapeo del ID del producto
        elements: item.elements.map((element) => ({
          description: element.descripcion || "", // Ajustar el campo descripción
          name: element.nombre.replace(/{{|}}/g, ""), // Limpiar el campo nombre
        })),
      }));
      const initialValues = transformedData[0]?.elements.reduce((acc, element) => {
        acc[element.name] = ""; // Asigna un valor inicial vacío para cada campo
        return acc;
      }, {});
      setProductMappings(transformedData[0]?.elements);
      setFormValues(initialValues);
      setError(false);
    }
    catch(e){
      setProductMappings(false);
      setError(true);
    }
  };

  const saveMappings = async () => {
    if(!formValues) return;
    try{
      await post(LEXCORE_URL+`/store/save-mappings`,
        {
          ...formValues,
          productId: product
        },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`
          }
        }
      );
      setSuccess(true);
    }
    catch(e){
      setErrorSaving(true);
    }
  }

  const errorComponent = (<Error
    mensajeError="Ooops !"
    detallesError={"Ha ocurrido un problema al intentar cargar sus datos."}
    textoBoton={"Volver a intentar"}
    accion={() => {
      setProductMappings(false);
      setError(false);
    }}
  /> )

  const errorSavingComponent = (<Error
    mensajeError="Ooops !"
    detallesError={"Ha ocurrido un problema al intentar guardar su documento."}
    textoBoton={"Volver a intentar"}
    accion={() => {
      setProductMappings(false);
      setError(false);
    }}
  /> )

  useEffect(() => {
    if (!productMappings && user?.accessToken) getMappings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, productMappings,error]);

  const handleInputChange = (e, field) => {
    setFormValues({
      ...formValues,
      [field]: e.target.value,
    });
  }

  return (
    <div className='flex w-full flex-col px-12 py-12'>
      <div className="text-4xl my-10">
        <span className="text-gray-100 font-semibold">Rellena los datos de tu documento!</span><br/>
      </div>
      { error? errorComponent : errorSaving?  errorSavingComponent : (
        <>
          <div className='flex flex-row gap-8 flex-wrap'>
            { productMappings && productMappings.map((mapping, index) => (
              <div className='my-2' style={{ flex: '1 1 calc(25% - 16px)' }}>
                <MappingInput
                key={index}
                label={mapping.name}
                onChange={ (e) => {handleInputChange(e, mapping.name)}}
                value={formValues[mapping.name]}
                />
              </div>))
            }
          </div>
          <div className='flex flex-row gap-8 flex-wrap mt-12'>
            <button onClick={() => {saveMappings()}} className="bg-green-700 hover:shadow-black shadow-md text-white rounded p-2 w-full xl:w-1/4" >Enviar</button>
          </div>
        </>

      ) }
      <ModalSuccess
        mensaje={"Envio exitoso!"}
        mensajeAdicional={"Se ha guardado tu documento exitosamente, le llegara a su correo electrónico."}
        textoBoton={"Regresar a la tienda"}
        isOpen={success}
        onClose={()=>{
          setSuccess(false);
          navigate("/tienda");
        }}
      />
    </div>
  )
}

export default LexcoreMappings;