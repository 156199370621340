export const QueryInput = (props) =>{

    const { query, onQuery, enviarPregunta, isSending, className} = props;

    return(

        <div className={`flex w-full h-12 justify-center ${className}`}>
        <textarea className="resize-none w-1/2 rounded-tl rounded-bl bg-primarycolorcard p-3 h-12 text-white outline-none"
                  disabled={isSending} 
                  onChange={ (e) => {onQuery(e.target.value)}}
                  onKeyDown={ (e) => {enviarPregunta(e)} }
                  value={query} 
                  rows={2} 
                  placeholder="Escribe tu pregunta"/>
        <button onClick={ (e) => {enviarPregunta(e)} } disabled={isSending}>
         <img src="/busqueda.svg" className="w-10 h-12 text-white bg-primarycolorcard rounded-tr rounded-br" alt="Icono de busqueda"/>
        </button>
       </div>
    
    );
    
}