
export const InputWithTitle = ({label, value, placeholder, onChange, tipo="text"}) => {
    return(
    <>
     <section>
     <h2 className="text-white">{label}</h2>
     <input
       onChange={onChange} 
       className=" h-8 p-2 rounded bg-primarycolorcard text-white outline-none focus:ring-1 focus:ring-white" 
       type={tipo} 
       value={value}
       placeholder={placeholder}/>
     </section>
    </>);
}
