import {Modal} from "./modal/modal";
import {ModalHeader} from "./modal/modalHeader";
import {ModalBody} from "./modal/modalBody";
import {ModalFooter} from "./modal/modalFooter";


export const ModalSuccess = (props) => {
    const {isOpen, onClose, mensaje, mensajeAdicional,textoBoton} = props;
    return(
        <Modal active={isOpen} action={()=>{}}>
          <ModalHeader
            titulo=""
            urlImagen="confirmar.png"
            onClose={onClose}
          />

          <ModalBody>
            <h2 className="text-xl font-bold">{mensaje}</h2>
            <p>{mensajeAdicional}</p>
          </ModalBody>
          <ModalFooter>
            <button onClick={onClose} className="bg-secondarycolorback text-white rounded p-2">{textoBoton}</button>
          </ModalFooter>
        </Modal>
    )

}