import {Modal} from "./modal/modal";
import {ModalHeader} from "./modal/modalHeader";
import {ModalBody} from "./modal/modalBody";
import {ModalFooter} from "./modal/modalFooter";

export const ModalDelete = ({isOpen, accion, onClose, pregunta, parrafo, textoConfirmar, textoCancelar}) => {
  
    if(!isOpen) return("");
  
    return(
        <Modal active={isOpen} action={()=>{}}>

          <ModalHeader 
           titulo=""
           urlImagen="eliminar.png"
           onClose={onClose}
          />

          <ModalBody>
           <h1>{pregunta}</h1>
           <p>{parrafo}</p>
          </ModalBody>

          <ModalFooter>
            <button onClick={onClose} className="bg-secondarycolorback text-white rounded p-2" >{textoCancelar}</button>
            <button onClick={()=>{}} className="bg-deleted text-white rounded p-2" >{textoConfirmar}</button>
          </ModalFooter>

        </Modal>
    )

}