import { useId, useState } from 'react';
import { analytics } from '../../config/firebase';

export const MessageSimilares = ({ user, listaSimilares, abrirFuente }) => {
  const [isActive, setIsActive] = useState(false);
  if (listaSimilares.length === 0) return;

  return (
    <section className="flex flex-col gap-3 mb-6">
      <button
        onClick={() => setIsActive(!isActive)}
        className="bg-primarycolorcard p-2 font-bold rounded text-white STEP-ARTICURLOS-SIMILARES-CHAT"
      >
        Articulos similares
      </button>
      {isActive &&
        listaSimilares.map((similar, index) => (
          <div 
            key={`${useId}-lista-similares-${index}`}
            className="flex flex-col gap-3"
          >
            {
              similar.articulos.map(item => (
                <div className='md:inline-flex md:gap-1 flex-row flex gap-3'>
                  <button
                    key={`${useId}-similares-${item.articulo}`}
                    title={`Abrir el archivo PDF en la pagina ${item.pagina}`}
                    onClick={() => { analytics("abrir_pdf_pagina_articulo_similar", { articulo: item.articulo, pagina: item.pagina, user }); abrirFuente(item.url, item.pagina); }}
                    className="hidden md:flex bg-terciarycolorcard p-2 rounded text-secondarytext text-left w-full justify-center">
                      {item.ley} - {`${item.articulo} ${item.resumen}`}
                    </button>
                  <button className="bg-secondarycolorback p-1 rounded text-secondarytext w-full md:w-32">
                    <a href={`${item.url}#page=${item.pagina}`} target='_blank' rel="noreferrer">
                    <span className='flex md:hidden justify-center'>{item.articulo} - {`${item.resumen}`}</span>
                    <span className='hidden md:flex text-center justify-center'>Ir a la fuente</span>
                    </a>
                  </button>
                </div>
              )
              )
            }
          </div>
        ))
      }
    </section>)
}