import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import ChatSidebar from './chatSidebar';
import { Source } from './source';
import { ModalError } from '../modalError';

export const ChatLayout = (props) => {

    const { user, userPermission } = props;

    const [ chats, setChats ] = useState(null);
    const [ fuente, setFuente] = useState({ 
                                      "isOpen": false, 
                                      "url":"",
                                      "pagina":1,
                                      "scjn":false,
                                      "query":false,
                                      "answer":'',
                                      "target": ''
                                    })

    const [loadingChats, setLoadingChats] = useState(true);
    const [errorChats, setErrorChats] = useState(false);
    const [openModalErrorChat, setOpenModalErrorChat] = useState(false);
    const [refetchChats, setRefetchChats] = useState(false);

    const abrirFuente = (ruta, pagina, scjn=false, query='', answer='', target='') => {
      setFuente({
        "url":ruta,
        "isOpen": true ,
        "pagina":pagina, 
        "scjn": scjn, 
        'query':query,
        "answer":answer,
        "target": target
      });
    }

    const cerrarFuente = () => {setFuente({...fuente, "isOpen":false})};

    const reintentarObtenerChats = () => {
      setRefetchChats(true);
      setLoadingChats(true);
      setErrorChats(false);
      setOpenModalErrorChat(false);
    } 

    return(
     <section className="flex w-full">
      <ChatSidebar user={user}
                   chats={chats}
                   setChats={setChats}
                   cerrarFuente={cerrarFuente}
                   loadingChats={loadingChats}
                   onLoadingChats={setLoadingChats}
                   errorChats={errorChats}
                   onErrorChats={setErrorChats}
                   onOpenModal={setOpenModalErrorChat}
                   refetchChats={refetchChats}
      />
      <Outlet context={[user, chats, setChats, abrirFuente, userPermission, loadingChats, errorChats]}/>
      <Source
        user={user}
        fuente={fuente}
        cerrarFuente={cerrarFuente}
      />
      <ModalError
       isOpen={openModalErrorChat}
       accion={reintentarObtenerChats}
       onClose={() => {setOpenModalErrorChat(false)}}
       
       tituloMensaje="Oops !"
       mensaje="Ha ocurrido un problema al intentar obtener sus chats."
       textoReintentar="Volver a intentar"
      
      />
     </section>
    )
}