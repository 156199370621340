
export const ModalHeader = ({titulo, urlImagen, onClose}) =>{
    return(
    <header className="flex flex-col items-center opacity-100 justify-between p-1 px-8 bg-[#2F2F2F] gap-1">
        <div className="flex justify-end w-full">
         <h1 className="text-3xl text-white font-semibold">{titulo}</h1>
         <button onClick={onClose} className="text-4xl text-white font-normal">x</button>
        </div>
        <img src={urlImagen} alt="" className="mb-6" />
    </header>
    )
}