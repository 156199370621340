import {
    collection,
    getDocs, 
    doc,
    getDoc,
    where,
    query,
    and,
    setDoc,
    getCountFromServer,
    limit,
    orderBy,
    startAfter,
    documentId
  } from "firebase/firestore";
  import { db } from "./firebase";
  
  export const getFirestore = async (name, sortBy, key, offset) => await getDocs( query( collection(db, name), orderBy(sortBy, "desc"), startAfter(key), limit(offset) ) );  

  export const getUserGeneralData = async (name, condition) => await getDocs( query(collection(db,name), and( where( condition[0], "==", condition[1]) ) ) );

  export const getAllProducts = async (name, sortBy) => await getDocs( query( collection(db, name), orderBy(sortBy, "desc") ) );

  export const queryByIdFirestore = async (name, id) => await getDoc( doc( db, name, id ) );

  export const getQueryWhere = async (name, condition) => await getDocs( query( collection(db, name), and( where(condition[0], ">=", condition[1]), where(condition[0], "<=", condition[1] + '~')  ) ) );

  export const getQueryWhereIf = async (name, userCondition, condition) => await getDocs( query( collection(db, name), and( 
    where(userCondition[0], "==", userCondition[1]), 
    where(condition[0], ">=", condition[1]), 
    where(condition[0], "<=", condition[1] + '~')  
  ) ) );

  export const getQueryByIds = async (name, condition) => await getDocs( query( collection( db, name ), and( where( documentId(), 'in', condition ) ) ) )

  export const getQueryContains = async (name, condition) => await getDocs( query( collection( db, name ), and( where( condition[0], 'in', condition[1] ) ) ) )

  export const getQueryIf = async (name, sortBy, key, offset, condition) => await getDocs( query( collection(db, name), and( where(condition[0], "==", condition[1]) ), orderBy(sortBy, "desc"), startAfter(key), limit(offset) ) );

  export const createDoc = async (name, data) => await setDoc( doc( db, name, data['nombre'] ), data , { merge: true } );

  export const createDocDate = async (name, data) => await setDoc( doc( db, name, `${data['fecha']}` ), data, { merge: true } );

  export const countFirestore = async (name) => (await getCountFromServer( collection( db, name ) )).data().count;

  export const countFirestoreIf = async (name, condition) => (await getCountFromServer( query( collection(db, name), and( where(condition[0], "==", condition[1]) ) ) )).data().count;