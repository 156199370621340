import React, { useCallback, useEffect, useMemo, useState } from "react";
import Joyride, { STATUS } from "react-joyride";

const joyrideStyles = {
  options: {
    zIndex: 10000,
  },
};

function Tour({ steps, localStorageKey }) {
  const [run, setRun] = useState(false);

  useEffect(() => {
    if (!localStorageKey) {
      setRun(true);
      return;
    }

    try {
      const tourViewed = window.localStorage.getItem(localStorageKey);
      if (!tourViewed) {
        window.localStorage.setItem(localStorageKey, "1");
        setRun(true);
      }
    } catch (error) {
      console.error("Error accessing localStorage:", error);
      setRun(true);
    }
  }, [localStorageKey]);

  const handleJoyrideCallback = useCallback((data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      console.log("Tour finalizado o saltado.");
    }
  }, []);

  const tour = useMemo(
    () => (
      <Joyride
        callback={handleJoyrideCallback}
        continuous={true}
        run={run}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={steps}
        styles={joyrideStyles}
        disableScrollParentFix={true}
      />
    ),
    [steps, handleJoyrideCallback, run]
  );

  return <>{tour}</>;
}

export default Tour;
