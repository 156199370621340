import {useId} from 'react';

import { Feature } from "./feature";

export const FeatureList = (props) => {

    const {features} = props;
    return(
        <article className="text-white">
            {features.map(feature => (
             <Feature key={`${useId} ${feature}`} descripcion={feature}/>
            ))}
        </article>
    )
}