import { Link, useLocation } from "react-router-dom";

const NavLink = (props) => {

  const { icon, url, text, action, className } = props;
  const location = useLocation();
  return (
    <Link to={url} className={`flex items-center px-6 py-2 mt-4 ${ location.pathname === url ? "bg-slate-500" : "bg-test" } text-white hover:bg-slate-500  bg-opacity-25 flex-col w-full rounded-md ${className}`}>
      <button
        onClick={action}
        className="flex flex-row"
      >
        {icon && <img
          alt="dashboard icon"
          src={icon}
          className="w-6 h-6"
          onClick={action}
        />}
        <span className='mx-3 my-1'>{text}</span>
      </button>
    </Link>
  )
}

export default NavLink;