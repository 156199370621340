import React from "react";
import { getIdTokenResult } from "firebase/auth";

const Config = (props) => {

  const copyClip = async (user)=>{
    const { token } = await getIdTokenResult(user, true);
    navigator.clipboard.writeText(token);
    alert('Token copiado')
  }

  const clearStorage = ()=>{
    localStorage.clear();
    alert('Borrador Limpiado')
  }

  const { user } = props;
  return (
    <div className="flex w-full flex-col px-12">
      <div className="text-4xl my-10">
        <span className="text-gray-200 font-semibold">Configuración</span>
      </div>
      <div className="flex">
        <span className="text-gray-200">Token de desarrollo:</span>
        <button className="mx-2 text-gray-200 font-semibold" onClick={()=>copyClip(user)}>Copiar</button>
      </div>
      <div className="flex">
        <span className="text-gray-200">Limpiar Borrador:</span>
        <button className="mx-2 text-gray-200 font-semibold" onClick={()=>clearStorage()}>Limpiar</button>
      </div>
    </div>
  )
};

export default Config;