


export const ResponseLoading = ({isSending, sendingMessageRef}) => {

  if(!isSending) return;

  return(
   <article>
     <p ref={sendingMessageRef} className="text-white p-5">Procesando su pregunta, espere un momento...</p>
   </article>
  )
}