import {useId, useState} from 'react';
import { analytics } from '../../config/firebase';


export const MessageLeyes = ({user, leyes, abrirFuente}) => {
  const [isActive, setIsActive] = useState(false);
    
    return(
    <section className="flex flex-col gap-3">
     <button 
      onClick={()=> setIsActive(!isActive)}
      className="bg-primarycolorcard p-2 font-bold rounded text-white STEP-ARTICURLOS-RESPALDO-CHAT "
    >
      Articulos de respaldo
    </button>
     { isActive && 
      leyes.map( ley => (
        <div className='md:inline-flex md:gap-1 flex-row flex gap-3'>
          <button 
          title={`Abrir el documento PDF en la pagina ${ley.pagina}`}
          onClick={()=>{analytics("abrir_pdf_pagina_articulo_respaldo",{articulo:ley.articulo, pagina:ley.pagina, user}) ;abrirFuente(ley.url, ley.pagina);}} 
          key={`${useId} ${ley.articulo}`} 
          className="hidden md:flex bg-secondarycolorback p-1 rounded text-secondarytext w-full justify-center"> 
          
          {ley.ley} - {ley.articulo}

        </button>
        <button className="bg-secondarycolorback p-1 rounded text-secondarytext w-full md:w-32">
          <a href={`${ley.url}#page=${ley.pagina}`} target='_blank' rel="noreferrer">
            <span className='flex md:hidden justify-center'>{ley.ley} - {`${ley.articulo}`}</span>
            <span className='hidden md:flex text-center justify-center'>Ir a la fuente</span>
          </a>
        </button>
        </div>
      ))
     }
    </section>)
}