import firebase from 'firebase/compat/app';
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyBUec9Wobpvw7taPMg3nYVLrmn2OffKmf4",
  authDomain: "a-dike.firebaseapp.com",
  projectId: "a-dike",
  storageBucket: "a-dike.appspot.com",
  messagingSenderId: "472566164634",
  appId: "1:472566164634:web:81a31c0e830723db904323",
  measurementId: "G-7TY0YS1XCP"
};

const FirebaseApp = firebase.initializeApp(firebaseConfig);
const db = getFirestore(FirebaseApp);
const storage = getStorage(FirebaseApp);
const analytics = (name, data) => {logEvent( getAnalytics(FirebaseApp), name, data)};

export { FirebaseApp, db, storage, analytics };