
import { useState } from 'react';
import NavLink from '../components/NavLink';

import { ModalError } from "../components/modalError";
import { Error } from "../components/error";
import { ErrorSidebar } from "../components/errorSidebar"  

export const ErrorPruebas = () => {

    const [mostrarModal, setMostrarModal] = useState(true);

    return(
     <section className="flex w-full">


      {/* Simulacion de como se veria un error en una Sidebar */}
      <aside className={`flex h-screen duration-300 transform w-64`}>
      <div className={` h-screen z-20 w-full overflow-y-auto transition duration-300 transform bg-sliderbar`}>
        <h1 className="text-white text-lg font-semibold text-center mt-4">Sidebar</h1>
        <div className="mt-10 flex items-center flex-col w-full">
          {false && <NavLink icon="/icons/configuration.png" url="/" text="Configuracion adicional"/>}
          <NavLink icon="/icons/chat.png" url="/pruebaError" text="Mostrar modal" action={()=>{setMostrarModal(true)}}/>
          <ErrorSidebar
            mensajeError="Ooops!"
            detallesError="No se puede establecer conexion"
            textoBoton="Volver a intentar"
          />
        </div>
      </div>
    </aside>


      {/* Simluacion de como se veria un error en una vista*/}
      <Error
       mensajeError="Ooops !"
       detallesError="No se puede establecer conexion"
       textoBoton="Volver a intentar"
      />


      {/* Modal que se muestra cuando ocurre un error */}
      <ModalError
        isOpen={mostrarModal}
        accion={()=>{}}
        onClose={()=>{setMostrarModal(false)}}
        tituloMensaje="Ooops !"
        mensaje="No se puede establecer conexion"
        textoReintentar="Volver a intentar"
      />


     </section>
    )
}