


export const Error = (props) => {
    const {mensajeError, detallesError, accion, textoBoton} = props;
    return(
    <article className="flex flex-col w-full">

    <header className="flex flex-col items-center opacity-100 justify-between p-1 px-8 bg-[#2F2F2F] gap-1">
        <img src="" alt="Icono de error" className="mb-6 text-white" />
    </header>

    <section className="text-white">
        <h1 className="text-xl font-bold">{mensajeError}</h1>
        <p>{detallesError}</p>
    </section>

    <footer>
     <button onClick={accion} className="bg-secondarycolorback text-white rounded p-2" >{textoBoton}</button>
    </footer>

    </article>);
    
}