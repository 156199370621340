import { useState, useEffect, useRef } from "react";
import { post } from "../../config/axios";
import { QueryInput } from "./queryInput";
import { SCJNMessageList } from "./scjnMessageList";
import { ResponseLoading } from "./responseLoading";
import { ModalError } from "../modalError";
import { BASE_URL } from "../../constants";

export const SCJN = (props) => {

  const { user, fuente, answer, target } = props;
  const [isSending, setIsSending] = useState(false);
  const [options, setOptions] = useState([]);
  const [response, setResponse] = useState('');
  const sendingMessageRef = useRef(null);
  const scrollRef = useRef(null);
  const [openErrorSending, setOpenErrorSending] = useState(false);

  useEffect(() => {
    setIsSending(true);
    const getInfoSCJN = async () => {
      const formData = new FormData();
      formData.append("query", fuente)
      try{
        const { data } = await post(BASE_URL+`/chats/send-advanced-${target}-query`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${user.accessToken}`
          },
        });
        if (data) setOptions(data);
        setIsSending(false);
      }
      catch(e){
        setOpenErrorSending(true);
        setIsSending(false);
      }
    }
    getInfoSCJN()

    return () => {
      setOptions([])
    };
  }, [fuente, user.accessToken, target])

  useEffect(() => {
    if (isSending && scrollRef.current && sendingMessageRef.current) {
      scrollRef.current.scrollTo({
        top: sendingMessageRef.current.offsetTop,
        behavior: 'smooth'
      })
    }

  }, [isSending]);

  const idDetalle = async (index) => {
    const formData = new FormData();
    formData.append("id", `${index}`)
    formData.append('rag', answer)
    try{
      const { data } = await post(BASE_URL+`/chats/get-advanced-${target}-details`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.accessToken}`
        },
      });
      return data;
    }catch(e){
      setOpenErrorSending(true);
      setIsSending(false);
     }
 
  }

  const enviarPregunta = async (event=null, resend=false) => {

    if (resend || event.key === "Enter" || event?.type === "click") {
      setIsSending(true);
      const formData = new FormData();
      formData.append("response", response)
      try {
        const { data } = await post(BASE_URL+`/chats/send-advanced-${target}-query`, formData, {
            headers:
            {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${user.accessToken}`
            },
          });

        setOptions(data);
        setResponse("");
        setIsSending(false);

        } catch (e) { 
          setIsSending(false);
          setOpenErrorSending(true);
        }
      }
  }

  return (
    <div className="flex-1 flex flex-col justify-end h-screen p-5 bg-primarycolorback">
      <QueryInput
        enviarPregunta={enviarPregunta}
        isSending={isSending}
        query={response}
        onQuery={setResponse}
      />
      <div ref={scrollRef} className="overflow-y-scroll mt-6">

        <SCJNMessageList
          user={user}
          options={options}
          fuente={fuente}
          idDetalle={idDetalle}
        />

        <ResponseLoading
          isSending={isSending}
          sendingMessageRef={sendingMessageRef}
        />
        <ModalError
        isOpen={openErrorSending}
        accion={()=>{enviarPregunta(null, true)}}
        onClose={()=>{setOpenErrorSending(false)}}

        tituloMensaje="Ooops !"
        mensaje="Ha ocurrido un problema al intentar enviar su mensaje."
        textoReintentar="Volver a intentar"
      />
      </div>
    </div>
  )
};