import { useId } from "react";

const StoreSelection = ({store, onClick, active}) =>{
    return(
        <nav className="flex flex-wrap text-white gap-6">
         {
          store.map( store => (
           <button
            onClick={() => onClick({store})}
            key={`${useId} ${store.id}`} className={`p-2 ${ store.id === active? "bg-gray-500 text-white":"bg-primarycolorcard text-slate-300" } hover:bg-gray-500  hover:text-white rounded`}>{store.name}</button>
          ))
         }
        </nav>
    )
}

export default StoreSelection;