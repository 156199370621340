export const MessageScjn = (props) => {
    const { abrirFuente, query, message } = props;
    return (
        <section className="flex flex-col gap-5">
            <button
                onClick={() => abrirFuente('', '', true, query, message, 'sentencias')}
                className="bg-primarycolorcard p-2 font-bold rounded text-white"
            >
                Sentencias - SCJN
            </button>
            <button
                onClick={() => abrirFuente('', '', true, query, message, 'tesis')}
                className="bg-primarycolorcard p-2 font-bold rounded text-white"
            >
                Tesis - SCJN
            </button>
        </section>)
}