


export const ErrorSidebar = (props) => {
    const {mensajeError, detallesError, accion, textoBoton} = props;
    return(
    <article className="flex flex-col w-full">

     
    
    <section className="text-white">
        <h1 className="text-xl font-bold">{mensajeError}</h1>
        <p>{detallesError}</p>
    </section>

    <footer>
     <button onClick={accion} className="bg-secondarycolorback text-white rounded p-2" >{textoBoton}</button>
    </footer>

    </article>);

}