import { useId, useState } from 'react';
import { analytics } from '../../config/firebase';

export const MessageRelacionados = ({ user, listaRelacionados, abrirFuente }) => {
  const [isActive, setIsActive] = useState(false);
  if (listaRelacionados.length === 0) return;

  return (
    <section className="flex flex-col gap-5">
      <button
        onClick={() => setIsActive(!isActive)}
        className="bg-primarycolorcard p-2 font-bold rounded text-white STEP-ARTICURLOS-RELACIONADOS-CHAT text-center justify-center"
      >
        Articulos Relacionados
      </button>
      {isActive &&
        listaRelacionados.map(relacionado => (

          <div key={`${useId}-relacionado-${relacionado.ley}`} className="flex flex-col gap-3">

            <h3 className="bg-terciarycolorcard p-2 rounded text-secondarytext text-center"> {`Relacionados al ${relacionado.ley.toLowerCase()}`}</h3>
            <p className="bg-terciarycolorcard p-2 rounded text-secondarytext">Resumen: {relacionado.resumen} </p>

            {
              relacionado.articulos.map((item, index) => (
                <div className='md:inline-flex md:gap-1 flex-row flex gap-3' >
                  <button
                    title={`Abrir el archivo PDF en la pagina ${item.pagina}`}
                    onClick={() => { analytics("abrir_pdf_pagina_articulo_relacionados", { articulo: item.articulo, pagina: item.pagina, user }); abrirFuente(item.url, item.pagina); }}
                    className="hidden md:flex bg-secondarycolorback p-1 rounded text-secondarytext w-full justify-center"
                    key={`similares-${index}-${item.articulo}`}
                  >
                    {item.ley} - {`${item.articulo}`}
                  </button>
                  <button className="bg-secondarycolorback p-1 rounded text-secondarytext w-full md:w-32">
                    <a href={`${item.url}#page=${item.pagina}`} target='_blank' rel="noreferrer">
                    <span className='flex md:hidden justify-center'>{item.ley} - {`${item.articulo}`}</span>
                    <span className='hidden md:flex text-center justify-center'>Ir a la fuente</span>
                    </a>
                  </button>
                </div>
              )
              )
            }

          </div>
        ))
      }
    </section>)
}