import React, { useState } from 'react';
import NavLink from '../NavLink';
import { Historial } from '../../views/historial';
import { analytics } from '../../config/firebase';

const ChatSidebar = (props) => {
  const {
    user,
    chats,
    setChats,
    cerrarFuente,
    loadingChats,
    onLoadingChats,
    errorChats,
    onErrorChats,
    onOpenModal,
    refetchChats
  } = props;

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); 
  };

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      {/* Botón para mostrar/ocultar el menú en pantallas pequeñas */}
      <button 
        onClick={toggleMenu} 
        className="fixed z-40 top-4 right-4 p-2 bg-gray-800 text-white rounded-md STEP-VIEW-CHATS">
        <img src="/icons/chat.png" alt="Menu" className="w-6 h-6" />
      </button>
      
      <aside 
        className={`fixed inset-y-0 right-0 flex flex-col h-screen w-64 transition-transform duration-300 transform ${menuOpen ? 'translate-x-0' : 'translate-x-full'} bg-sliderbar z-30`}>
        <div className="h-full overflow-y-auto">
          <h1 className="text-white text-2xl text-left my-4 pl-4">Chat</h1>
          <div className="flex items-center flex-col w-full">
            {false && <NavLink icon="/icons/configuration.png" url="/" text="Configuracion adicional" action={()=> {cerrarFuente(); toggleMenu(); }} />}
            <span onClick={() => { analytics("intencion_de_crear_chat", { user }) }}>
              <NavLink icon="/icons/chat.png" url="/chat/create" text="Nueva conversación" action={()=> {cerrarFuente(); toggleMenu(); }} />
            </span>
            <Historial 
              user={user} 
              chats={chats}
              setChats={setChats}
              loading={loadingChats}
              setLoading={onLoadingChats}
              error={errorChats}
              setError={onErrorChats}
              setOpen={onOpenModal}
              refetch={refetchChats}
              cerrarFuente={()=> {cerrarFuente(); toggleMenu(); }}
            />
          </div>
        </div>
      </aside>
    </>
  );
};

export default ChatSidebar;
