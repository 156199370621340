import { useEffect, useState } from "react";
import { useOutletContext } from 'react-router-dom';
import { InputWithTitle } from "../../components/inputWithTitle";
import { Error } from "../../components/error";
import { Modal } from "../../components/modal/modal";
import { ModalSuccess } from '../../components/modalSuccess';
import { ModalError } from '../../components/modalError';
import { get,post } from "../../config/axios"
import { analytics} from "../../config/firebase";
import { BASE_URL } from "../../constants";


export const DatosPersonales = (props) => {

    const [user] = useOutletContext();

    const [ userInformation, setUserInformation ] = useState({ "name":"",
                                                               "email":"",
                                                               "password":"",
                                                               "estado":"",
                                                               "ciudad":"",
                                                               "municipio": "",
                                                               "uid":user.uid
                                                              
    });


    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(false);
    const [ openModalErrorLoading, setOpenModalErrorLoading ] = useState(false);
    const [ refetch, setRefetch ] = useState(false);

    const [ sendingChanges, setSendingChanges ] = useState(false);
    const [ openModalSuccess, setOpenModalSuccess ] = useState(false);
    const [ openModalError, setOpenModalError ] = useState(false);
    
    const actualizarDatos = async () => {
     setOpenModalError(false);
     setSendingChanges(true);
     analytics('actualiza_datos', {user});
     try
     {
      await post(BASE_URL+`/user/update`, userInformation,
      {
       headers:
       {
        Authorization: `Bearer ${user.accessToken}`
       },
      });
      setSendingChanges(false);
      setOpenModalSuccess(true);
     }catch(e){
      setSendingChanges(false);
      setOpenModalError(true);
     }

    }

    const reintentarObtenerDatosGenerales = () => {
     setLoading(true);
     setError(false);
     setOpenModalErrorLoading(false);
     setRefetch(true);
    }

    useEffect(()=>{
     const obtenerDatosGenerales = async () => {
      setLoading(true);
      try
      {
      const { data } = await get(BASE_URL+`/user/get-data`,{
        headers:{
          Authorization: `Bearer ${user.accessToken}`
        }
      });


       setUserInformation(data);
       setLoading(false);
      }catch(e){
        setLoading(false);
        setError(true);
        setOpenModalErrorLoading(true);
      }
     }
     obtenerDatosGenerales();
    },[refetch, user])


    //Cargando datos generales
    if(loading) return(<p className="text-white p-3 animate-pulse text-center">Cargando datos generales...</p>)


    //Ha ocurrido un problema al cargar los datos generales.
    if(error) return(
    <section>

      <Error
        mensajeError="Ooops !"
        detallesError={"Ha ocurrido un problema al intentar cargar sus datos."}
        textoBoton={"Volver a intentar"}
        accion={reintentarObtenerDatosGenerales}
      />


      <ModalError
        isOpen={openModalErrorLoading}
        accion={reintentarObtenerDatosGenerales}
        onClose={()=>{setOpenModalErrorLoading(false)}}
        tituloMensaje="Ooops !"
        mensaje={"Ha ocurrido un problema al intentar cargar sus datos."}
        textoReintentar={"Volver a intentar"}
      />
    </section>)


    //Vista de datos generales
    return(
     <section className="flex flex-col gap-1 pl-4">
      <h1 className="text-3xl text-white my-2">Datos generales</h1>
      <section className="flex flex-row flex-wrap gap-10">
       <InputWithTitle 
         label="Nombre"
         onChange={ (e) => {setUserInformation({...userInformation, "name":e.target.value})}}
         value={userInformation["name"]}
         placeholder="Nombre de ejemplo"/>
       <InputWithTitle 
         label="Correo" 
         onChange={ (e) => {setUserInformation({...userInformation, "email":e.target.value})}}
         value={userInformation["email"]}
         placeholder="ejemplo@gmail.com"/>
       <InputWithTitle 
         label="Contraseña"
         onChange={ (e) => {setUserInformation({...userInformation, "password":e.target.value})}}
         value={userInformation["password"]} 
         placeholder="**********"/>
       <InputWithTitle 
         label="Estado" 
         onChange={ (e) => {setUserInformation({...userInformation, "estado":e.target.value})}}
         value={userInformation["estado"]}
         placeholder="Ejemplo: Queretaro"/>
       <InputWithTitle 
         label="Ciudad"
         onChange={ (e) => {setUserInformation({...userInformation, "ciudad":e.target.value})}}
         value={userInformation["ciudad"]} 
         placeholder="Ejemplo: Queretaro"/>
       <InputWithTitle 
         label="Municipio" 
         onChange={ (e) => {setUserInformation({...userInformation, "municipio":e.target.value})}}
         value={userInformation["municipio"]}
         placeholder="Ejemplo: Queretaro"/>
      </section>
      <button onClick={actualizarDatos} className="bg-green-700 mt-4 rounded p-1 text-white w-40 ">Guardar datos</button>

      <ModalSuccess
       isOpen={openModalSuccess}
       onClose={()=>{setOpenModalSuccess(false)}}
       mensaje="¡Actualizacion exitosa!"
       mensajeAdicional="Se ha actualizado su informacion exitosamente."
       textoBoton="Volver a la configuracion"
      />

      <ModalError
        isOpen={openModalError}
        accion={actualizarDatos}
        onClose={()=>{setOpenModalError(false)}}
        tituloMensaje="Ooops !"
        mensaje={"Ha ocurrido un problema al intentar actualizar sus datos"}
        textoReintentar={"Volver a intentar"}
      />

      <Modal active={sendingChanges} action={actualizarDatos}> <p className="p-2 font-medium animate-pulse text-center">{"Actualizando informacion, espere un momento..."} </p></Modal>

     </section>
    );
}