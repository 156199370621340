import React from "react";


const Search = (props) => {
  const { isDate, placeholder, accion } = props;
  let debounceTimeout;
  const handleDebouncedChange = (event) => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => accion(event), 1000);
  };
  return (
    <div className="flex bg-primarycolorcard rounded w-full my-0 mx-auto">
      <div className="flex justify-end gap-1 w-full">
        <div className="icono w-8 flex">
          <img
            src="/busqueda.svg"
            alt="busqueda icono"
            className="busqueda-editsearch p-2"
          />
        </div>
        <input
          type="text"
          placeholder={placeholder}
          className="bg-primarycolorcard rounded text-white w-full"
          onChange={handleDebouncedChange}
        />
      </div>
      {isDate && (
        <div className="">
          <input
            type="date"
            className=""
          />
        </div>
      )}
    </div>
  )
};

export default Search;