import React, { useId, useState } from 'react';
import StoreSelection from '../components/store/storeSelection';
import LexcoreProducts from './user-products-views/lexcoreProducts';

const UserProducts = (props) => {
  const { user, updateToken, navigate } = props;
  const [activeStore, setActiveStore] = useState(2);

  const stores = [
    /*{
      id: 1,
      name: "A-Dike",
      component: <AdikeProducts key={useId} navigate={navigate} user={user} updateToken={updateToken} active={activeStore}/>
    },*/
    {
      id: 2,
      name: "Lexcore",
      component: <LexcoreProducts key={useId} navigate={navigate} user={user} updateToken={updateToken} active={activeStore}/>
    },
  ]

  const changeStore = ({store}) =>{
    setActiveStore(store.id);
  };

  //Vista de la tienda (Store)
  return (
    <div className='flex w-full flex-col px-12'>
      <div className="text-4xl my-10">
        <span className="text-gray-100 font-semibold">Bienvenido a tus productos!</span><br/>
        <span className="text-gray-400 font-semibold text-lg">Explora algo de lo que haz adquirido</span>
      </div>
      <div className="flex justify-between mb-8 flex-wrap ">
        {<StoreSelection onClick={changeStore} store={stores} active={activeStore} />}
      </div>
      {stores.map(store => store.id === activeStore && store.component)}
    </div>
  )
}

export default UserProducts;