import { useEffect } from 'react';
import { get } from '../config/axios';
import ChatNavLink from '../components/chat/chatNavLink';
import { BASE_URL } from '../constants';

export const Historial = (props) => {

  const { user, chats, setChats, cerrarFuente, loading, setLoading, error, setError, setOpen, refetch } = props;

  useEffect(() => {
    const obtenerChats = async () => {
     try{
      const { data } = await get(BASE_URL+`/chats/get-chats`,{
        headers:{
          Authorization: `Bearer ${user.accessToken}`
        }
      });
      
      setChats(data.chats);
      setLoading(false);

     }catch(e){
      setOpen(true);
      setLoading(false);
      setError(true);      
     }
    }

    if(user && user.accessToken) obtenerChats();

  }, [user, setChats, refetch, setError, setLoading, setOpen])

 


  if (loading) return (<p className="text-white animate-pulse">Cargando chats...</p>);

  if(error) return(<> </>)

  if (!chats) return (<p className="text-white mt-3">Parece que aun no tienes chats</p>);

  return (
    <article className="flex flex-col">
      <h3 className="text-white font-bold mt-3">Conversaciones recientes</h3>
      <div className="overflow-y-scroll mt-2 text-white">
        {
          chats.map((chat, index) => (
            <ChatNavLink
              key={`chat-${chat}`}
              user={user}
              id_chat={chat}
              action={cerrarFuente}
              icon=""
              url={`/chat/${chat}`}
              text={`Conversación ${index + 1}`} />
          ))
        }
      </div>

      
    </article>);
}