import { Error } from "../error";
import { UserProductCard } from "./userProductCard";

const UserProductsGrid = ({ productos, isLoading, error, user, onSelectedProduct, refetchProducts }) => {

  if(isLoading) return(<p className="text-white p-1 animate-pulse">Cargando tus productos...</p>)

  if(error) return(
    <section>

    <Error
      mensajeError="Ooops !"
      detallesError={"Ha ocurrido un problema al intentar cargar sus datos."}
      textoBoton={"Volver a intentar"}
      accion={refetchProducts}
    />
  </section>)


if(productos.length===0 ) return(<p className="text-white p-1">No se han encontrado productos</p>)
  return (
    <div className="flex flex-wrap gap-8 justify-around">
      {
        productos.map(producto => (
          <UserProductCard
            key={producto.id}
            id={producto.id}
            user={user}
            titulo={producto.name}
            descripcion={producto.description}
            usos={producto.uses}
            onSelectedProduct={onSelectedProduct}
          />
        ))
      }
    </div>
  );
}

export default UserProductsGrid;