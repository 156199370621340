import React, { useState, useEffect, useCallback } from 'react';
import UserList from '../components/usuarios/userList';
import { getFirestore, getQueryWhere, countFirestore } from '../config/firestore';
import { useParams } from 'react-router-dom';
import ModalCreateUser from '../components/usuarios/modalCreateUser';

const Usuarios = (props) => {

  const { userName } = useParams();
  const { userRole, userStatus, user } = props;
  const [ isModalActive, setIsModalActive ] = useState(false);
  const [ users, setUsers ] = useState([{
    "uid": "1234567890",
    "nombre": "Christopher Orea",
    "role": -1,
    "status": 1,
    "photo": "",
    "creado": 1000000000
  }]);
  const [ query, setQuery ] = useState('');
  const [ searching, setSearching ] = useState(false);
  const [ total, setTotal ] = useState(0);


  const getUsersCallback = useCallback(()=>{
    const getUsers = async () => {
      const response = await getFirestore('users', 'creado', users.length > 0 ? users.at(-1).creado : '', 5);
      const getTotal = await countFirestore('users');
      if (getTotal) setTotal(getTotal);
      if (response) {
        const data = response && response.docs.map(d => d.data());
        setUsers([...users, ...data]);
      }
    }
    getUsers();
  },[users]);

  useEffect(() => {
    if (users.length === 0 && !searching && !userName) getUsersCallback();
    if (userName && users.length === 0){
      const obtenerUsuario = async ()=>{
        const { docs } = await getQueryWhere('users', ["nombre", userName]);
        if (docs) {
          const data = docs.map(d => d.data());
          setUsers(data);
        }
      }
      obtenerUsuario()
    }
  }, [user, users, getUsersCallback, searching, userName]);

  return (
    <div className='flex w-full flex-col px-12'>
       <div className="text-4xl my-10">
        <span className="text-gray-200 font-semibold">Usuarios</span>
      </div>
      { userRole === -1 && userStatus  && 
        <UserList 
          user={user} 
          users={users} 
          query={query} 
          activateModal={()=> setIsModalActive(!isModalActive)}
          setQuery={setQuery}
          setUsers={setUsers}
          getUsers={getUsersCallback}
          hasMore={users.length < total && !userName}
          searching={searching}
          setSearching={setSearching}
        />
      }
      <ModalCreateUser 
        close={()=>setIsModalActive(!isModalActive)} 
        active={isModalActive}
        addUser={(user)=>setUsers([...users, user])}
      />
    </div>
  )
}

export default Usuarios;