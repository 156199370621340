import React from 'react'
import { useLocation } from 'react-router-dom'
import NavLink from './NavLink';
import Tour from '../useTour';
import { EMAIL } from '../constants';

const steps = [{}];

const Sidebar = (props) => {
  const { logOut, user, action, open, userRole, freeAccess } = props;

  const location = useLocation();
  return (
    <div
      className={`duration-300 transform bg-primarycolorcard md:max-w-xs ${
        open
          ? "md:flex md:h-auto md:sticky w-full z-50 fixed h-screen"
          : "md:flex md:h-auto md:sticky fixed w-0 h-screen"
      }`}
    >
      <div
        className={`${
          open ? 'md:translate-x-0 ease-out' : 'md:-translate-x-full ease-in'
        } h-screen z-30 w-full overflow-y-auto transition duration-300 transform  flex flex-col `}
      >
        {/* User Info */}
        <div className="mt-10 flex items-center flex-col w-full">
          <img
            alt="User"
            src={user.photoURL}
            className="w-24 h-24 rounded-full"
          />
          <div className='p-4 w-full'>
            <NavLink
              action={action}
              icon="/icons/chat.png"
              url="/chat/create"
              text="Chat"
              className="STEP-CHAT-INICIAL"
            />
            <NavLink
              action={action}
              icon="/icons/dashboard.png"
              url="/"
              text="Dashboard"
            />
            {userRole === -1 && (
              <NavLink action={action} icon="" url="/usuarios" text="Usuarios" />
            )}

            {false && (
              <NavLink
                action={action}
                icon="/icons/configuration.png"
                url="/configuracion"
                text="Configuracion"
              />
            )}
            {false && (
              <NavLink
                action={action}
                icon="/icons/store.png"
                url="/tienda"
                text="Tienda"
              />
            )}
            {false &&(
              <NavLink
                action={action}
                icon="/icons/store.png"
                url="/mis-productos"
                text="Mis productos"
              />
            )}
            <NavLink
              action={action}
              icon="/icons/upgrade_plan.png"
              url="/actualizar"
              text="Actualizar plan"
              className="STEP-UPGRADE-1 .STEP-UPGRADE-2"
            />
            {false && (
              <NavLink
                action={action}
                icon="/icons/token.png"
                url="/token"
                text="Token"
              />
            )}
            {false && (
              <NavLink
                action={action}
                icon="./icons/token.png"
                url="/pruebaError"
                text="Componentes error (Pruebas)"
              />
            )}
          </div>
        </div>

        {/* Free Access Section */}
        {freeAccess && (
          <div
            className={`flex items-center px-6 py-2 mt-4 text-yellow-500 bg-test bg-opacity-25 flex-col w-full`}
          >
            <span className="mx-3 my-1">
              { freeAccess >= 0 ? ` Te quedan ${freeAccess} días de acceso gratuito` : `No te queda acceso gratuito`}
            </span>
          </div>
        )}

        {/* Footer Section */}
        <div className="mt-auto flex flex-col sidebar-footermenu p-4">
          <NavLink
            action={() => {}}
            text="Creado por Consultor-IA"
            url={`mailto:${EMAIL}`}
            className="STEP-CONTACT"
          />
          <NavLink 
             action={() => {}}
             text="Reporta un problema."
             url={`https://wa.me/524422323179`}
             className="STEP-CONTACT"
          />
          <NavLink
            action={logOut}
            text="Log Out"
            className="STEP-LOGOUT"
          />
        </div>
      </div>

      {/* Open/Close Button */}
      {!location.pathname.includes("configuracion") && (
        <span
          className="sidebar-open-close fixed left-0 top-0 text-gray-500 z-50 text-3xl px-1 mx-2"
          onClick={action}
        >
          {open ? "x" : "..."}
        </span>
      )}
      <Tour steps={steps} localStorageKey="KEY-SIDEBAR" />
    </div>
  );
};

export default Sidebar;
