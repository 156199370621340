import {Modal} from "./modal/modal";
import {ModalHeader} from "./modal/modalHeader";
import {ModalBody} from "./modal/modalBody";
import {ModalFooter} from "./modal/modalFooter";

export const ModalError = ({isOpen, item, accion, onClose, tituloMensaje, mensaje, textoReintentar}) => {


    if(!isOpen) return("");

    return(
        <Modal active={isOpen} action={()=>{}}>

          <ModalHeader 
           titulo=""
           urlImagen=""
           onClose={onClose}
          />

          <ModalBody>
           <h2 className="text-xl font-bold">{tituloMensaje}</h2>
           <p> {mensaje} </p>
          </ModalBody>

          <ModalFooter>
            <button onClick={accion} className="bg-secondarycolorback text-white rounded p-2" >{textoReintentar}</button>
          </ModalFooter>

        </Modal>
    )

}