import React from 'react';
import { getQueryWhere } from '../../config/firestore';
import { UserRow, UserHeader } from './userTable';
import InfiniteScroll from 'react-infinite-scroll-component';
import Search from '../search';
import NewUser from './newUser';

const UserList = (props) => {

  const { 
    user, users, query, setQuery, setUsers, getUsers, hasMore,
    searching, setSearching, activateModal
  } = props;


  const searchQuery = async (e) => {
    const value = e.target.value
    setQuery(value);
    if (value){
      setSearching(true);
      const { docs } = await getQueryWhere('users', ["nombre", value]);
      if (docs) {
        const data = docs.map(d => d.data());
        setUsers(data);
        if (data.length > 0) {
          setSearching(false);
        }
      }
    }
    else{
      setUsers([]);
      setSearching(false)
    }
  }

  const renderUsers = !searching && users.length > 0 && users.map(u => {
    return (
      <UserRow 
        key={u.uid} 
        uid={u.uid}
        nombre={u.nombre} 
        dbRole={u.role} 
        dbStatus={u.status}
        photo={u.photo}
        fecha={new Date(u.creado).toDateString()}
        user={user}
      />
    );
  })

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-row gap-3 w-full'>
        <Search accion={searchQuery} query={query} />
        <NewUser action={activateModal} />
      </div>
      <div className='flex flex-col bg-gray-100 p-5 gap-5 rounded-lg'>
        <UserHeader />
        <InfiniteScroll
          dataLength={users.length}
          next={getUsers}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
        >
          {users.length > 0 && renderUsers}
        </InfiniteScroll>
      </div>
    </div>
  )

};

export default UserList;