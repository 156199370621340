import React, { useState, useEffect } from 'react';
import { ModalError } from '../../components/modalError';
import { get } from '../../config/axios';
import { LEXCORE_URL } from '../../constants';
import UserProductsGrid from '../../components/userProducts/userProductsGrid';

const LexcoreProducts = (props) => {

  const { user, navigate } = props;
  const [products, setProducts] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [openErrorLoading, setOpenErrorLoading] = useState(false);

  useEffect(() => {
    if ((!products) || refetch) getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, products, refetch]);

  const refetchProducts = () => {
   setIsLoading(true);
   setError(false)
   setOpenErrorLoading(false)
   setRefetch(true);
  }

  const getProducts = async () => {
   setIsLoading(true);
   setRefetch(false);
   if(!navigator.onLine)
   {
    setIsLoading(false);
    setError(true);
    setOpenErrorLoading(true);
   }
   else
   {
    if(!user.accessToken) return
    try{
     const response = await get(LEXCORE_URL+`/store/user-products`,{
      headers:{
        Authorization: `Bearer ${user.accessToken}`
      }
    });
     if(response)
     {
      const data = response.data.map(d => d);
      setProducts([...data]);
      setIsLoading(false);
     }
    }catch(e){
     setIsLoading(false);
     setError(true);
     setOpenErrorLoading(true);
    }
   }
  }

  //Vista de la tienda (Store)
  return (
    <div className='flex w-full flex-col'>
      <UserProductsGrid
        productos={products}
        isLoading={isLoading}
        error={error}
        user={user}
        onSelectedProduct={(event) => {
            navigate(`/mis-productos/mappings/?product=${event}&store=lexcore`);
        }}
        refetchProducts={refetchProducts}
      />

      <ModalError
        isOpen={openErrorLoading}
        accion={refetchProducts}
        onClose={()=>{setOpenErrorLoading(false)}}
        tituloMensaje="Ooops !"
        mensaje={"Ha ocurrido un problema al intentar cargar los productos."}
        textoReintentar={"Volver a intentar"}
      />

    </div>
  )
}

export default LexcoreProducts;