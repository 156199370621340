
import { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { InputWithTitle } from "../../components/inputWithTitle";
import { Error } from "../../components/error";
import { Modal } from "../../components/modal/modal";
import { ModalSuccess } from '../../components/modalSuccess';
import { ModalError } from '../../components/modalError';
import { post } from "../../config/axios"
import { analytics} from "../../config/firebase";
import { BASE_URL } from '../../constants';

export const Seguridad = () => {

  const [ user ] = useOutletContext();

  const [ securityData, setSecurityData ] = useState({"pregunta_1":"",
                                                  "pregunta_2":"",
                                                  "respuesta_pregunta_1":"",
                                                  "respuesta_pregunta_2":"",
                                                  "phoneNumber":""
  })


  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ openModalErrorLoading, setOpenModalErrorLoading ] = useState(false);
  const [ refetch, setRefetch ] = useState(false);

  const [ sendingChanges, setSendingChanges ] = useState(false);
  const [ openModalSuccess, setOpenModalSuccess ] = useState(false);
  const [ openModalError, setOpenModalError ] = useState(false);

  const handlePhoneNumber = (e) => {
   const entrada = e.target.value;
   if(!isNaN(entrada) && entrada.length<=10)
    setSecurityData({...securityData,"phoneNumber":entrada});
  }

  const actualizarDatosSeguridad = async () => {
    let userSecurity = { "uid":user.uid,"municipio":""};
    userSecurity = { ...userSecurity, ...securityData};
    
    setOpenModalError(false);
    setSendingChanges(true);
    analytics('actualiza_seguridad', {user});
    try
    {
      await post(BASE_URL+`/user/update`, userSecurity,
     {
      headers:
      {
       Authorization: `Bearer ${user.accessToken}`
      },
     });
     setSendingChanges(false);
     setOpenModalSuccess(true);
    }catch(e){
     console.log(e);
     
     setSendingChanges(false);
     setOpenModalError(true);
    }

   }

   const reintentarObtenerDatosSeguridad = () => {
    setLoading(true);
    setError(false);
    setOpenModalErrorLoading(false);
    setRefetch(true);
   }

   //
    // ----------- Pruebas -----------
    //

    // Funcion que simula el comportamiento de un llamada para obtener los datos generales

    const pruebaDatosSeguridad = useCallback((success=true)=>{
      return new Promise( (resolve, reject) => {
       setTimeout( () => {
        if(success)
         resolve({
          "data":{
           "pregunta_1":"¿Como se llama tu mascota?",
           "pregunta_2":"¿Cual es tu deporte favorito?",
           "respuesta_pregunta_1":"Rino",
           "respuesta_pregunta_2":"Natacion",
           "phoneNumber":"2229341028"
           }
          });
        else
         reject( new window.Error("Ha ocurrido un problema al intentar obtener los datos generales"));
      }, 1000);
      });
  },[])
  
  //
  // --------------------------------
  //

   useEffect(()=>{
    const obtenerDatosGenerales = async () => {
     setLoading(true);
     try
     {
      //Simulacion de obtener los datos de seguridad del usuario de la API. (Colocar el metodo post en la version final)
      const { data } = await pruebaDatosSeguridad(true) ;
      setSecurityData(data);
      setLoading(false);
     }catch(e){
       setLoading(false);
       setError(true);
       setOpenModalErrorLoading(true);
     }
    }
    obtenerDatosGenerales();
   },[refetch, pruebaDatosSeguridad])

    //Cargando datos generales
    if(loading) return(<p className="text-white p-3 animate-pulse text-center">Cargando datos de seguridad...</p>)


      //Ha ocurrido un problema al cargar los datos generales.
      if(error) return(
      <section>
  
        <Error
          mensajeError="Ooops !"
          detallesError={"Ha ocurrido un problema al intentar cargar sus datos."}
          textoBoton={"Volver a intentar"}
          accion={reintentarObtenerDatosSeguridad}
        />
  
  
        <ModalError
          isOpen={openModalErrorLoading}
          accion={reintentarObtenerDatosSeguridad}
          onClose={()=>{setOpenModalErrorLoading(false)}}
          tituloMensaje="Ooops !"
          mensaje={"Ha ocurrido un problema al intentar cargar sus datos."}
          textoReintentar={"Volver a intentar"}
        />
      </section>)
    
  // Vista de seguridad del usuario
  return (
    <section className="flex flex-col gap-4 pl-4">
      <h1 className="text-4xl text-white my-2">Seguridad</h1>

      <section className="flex flex-row flex-wrap gap-12">
      <section className="flex flex-col gap-3">
        <h2 className="text-2xl text-yellow-100">Preguntas de seguridad</h2>

        <h3 className="text-secondarycolortext text-lg">Pregunta 1</h3>
        <input
          onChange={ (e) => {setSecurityData({...securityData, "pregunta_1": e.target.value})}}
          className="h-8 p-1 rounded bg-primarycolorcard text-white outline-none focus:ring-1 focus:ring-white"
          value={securityData["pregunta_1"]}
          placeholder="Escribe tu pregunta"
        />
        <input
          onChange={ (e) => {setSecurityData({...securityData, "respuesta_pregunta_1": e.target.value})}}
          className="h-8 p-1 rounded bg-primarycolorcard text-white outline-none focus:ring-1 focus:ring-white"
          value={securityData["respuesta_pregunta_1"]}
          placeholder="Escribe la respuesta"
        />

        <h3 className="text-secondarycolortext text-lg">Pregunta 2</h3>
        <input
          onChange={ (e) => {setSecurityData({...securityData, "pregunta_2": e.target.value})}}
          className="h-8 p-1 rounded bg-primarycolorcard text-white outline-none focus:ring-1 focus:ring-white"
          value={securityData["pregunta_2"]}
          placeholder="Escribe tu pregunta"
        />
        <input
          onChange={ (e) => {setSecurityData({...securityData, "respuesta_pregunta_2": e.target.value})}}
          className="h-8 p-1 rounded bg-primarycolorcard text-white outline-none focus:ring-1 focus:ring-white"
          value={securityData["respuesta_pregunta_2"]}
          placeholder="Escribe la respuesta"
        />
      </section>

      <section className="flex flex-col gap-3">
        <h2 className="text-2xl text-yellow-100">Verificación telefonica </h2>

        <InputWithTitle
          onChange={handlePhoneNumber}
          label="Numero de celular"
          value={securityData["phoneNumber"]}
          placeholder="Numero de telefono"
        />
      </section>

      </section>
      <button onClick={actualizarDatosSeguridad} className="bg-green-700 mt-4 rounded p-1 text-white w-40 ">
        Guardar datos
      </button>

      <ModalSuccess
       isOpen={openModalSuccess}
       onClose={()=>{setOpenModalSuccess(false)}}
       mensaje="¡Actualizacion exitosa!"
       mensajeAdicional="Se ha actualizado su informacion de seguridad exitosamente."
       textoBoton="Volver a la configuracion"
      />

      <ModalError
        isOpen={openModalError}
        accion={actualizarDatosSeguridad}
        onClose={()=>{setOpenModalError(false)}}
        tituloMensaje="Ooops !"
        mensaje={"Ha ocurrido un problema al intentar actualizar sus datos de seguridad"}
        textoReintentar={"Volver a intentar"}
      />

      <Modal active={sendingChanges} action={actualizarDatosSeguridad}> <p className="p-2 font-medium animate-pulse text-center">{"Actualizando informacion, espere un momento..."} </p></Modal>

    </section>
  );
};
