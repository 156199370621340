
export const MappingInput = ({label, value, placeholder, onChange, tipo="text"}) => {
    return(
    <>
    <div className="flex items-center p-4 bg-primarycolorcard hover:shadow-black shadow-md text-white space-x-4 rounded-lg">
        <div className="flex-1">
          <p className="text-xl text-subtitleText capitalize">{label}</p>
            <input
            type="text"
            placeholder={placeholder}
            className="p-2 w-full bg-primarycolorcard border-hidden text-white hover:text-slate-300 focus:outline-none"
            onChange={onChange}
            value={value}
          />
        </div>
      </div>
    </>);
}
