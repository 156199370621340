import { useEffect, useState, useRef } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { get, post } from '../config/axios';

import { MessageList } from '../components/chat/messageList';
import { QueryInput } from '../components/chat/queryInput';
import { ResponseLoading } from '../components/chat/responseLoading';
import { Error } from '../components/error';
import { ModalError } from '../components/modalError';
import { analytics } from '../config/firebase';

//Libreria
import Tour from '../useTour';

//Variable temporal para probar cuando una respuesta tiene un cluster.
import { messageCluster } from '../mensajeConCluster';
import { BASE_URL } from '../constants';

export const Chat = () => {

  const scrollRef = useRef(null);
  const sendingMessageRef = useRef(null);

  const { id_chat } = useParams();

  const [user, chats, setChats, abrirFuente, userPermission] = useOutletContext();
  const [chat, setChat] = useState(id_chat);
  const [messages, setMessages] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [openErrorSending, setOpenErrorSending] = useState(false);

  const enviarPregunta = async (event=null, resend=false) => {

    analytics("enviando_pregunta",{chat:chat, user})

    if (resend || event.key === "Enter" || event?.type === "click") {
      if (messages.length === 0) {
        crearChat();
      }
      else {
        setIsSending(true);
        const formData = new FormData();
        formData.append("chat_id", chat)
        formData.append("query", query)
        try {
          const { data } = await post(BASE_URL+`/chats/send-message`, formData,
            {
              headers:
              {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.accessToken}`
              },
            });

          setMessages([...messages, data]);
          setQuery("");
          setIsSending(false);

          analytics("pregunta_respondida",{chat:chat, user})

        } catch (e) { 
          setIsSending(false);
          setOpenErrorSending(true);
        }
      }
    }
  }



  const crearChat = async (event) => {

    setIsSending(true);
    const formData = new FormData();
    formData.append("query", query)

    try{

     const { data } = await post(BASE_URL+`/chats/send-message`, formData,
      {
        headers:
        {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.accessToken}`
        },
      });
     if (chats.length > 0) {
      setChats([data.chat_id, ...chats]);
     }
     else 
     {
      setChats([data.chat_id]);
     }
     setIsSending(false);
     setMessages([data]);
     setChat(data.chat_id);
     setQuery("");

     analytics("chat_creado",{user})
    }catch(e){
     setOpenErrorSending(true);
     setIsSending(false);
    }

  }

  const reintentarObtenerMensajes = () => {
   setError(false);
   setOpenModal(false);
   setLoading(true);
   setRefetch(true);
  }

  useEffect(() => {
    const obtenerMensajes = async () => {
      setError(false);
      setRefetch(false);
      setLoading(true);
      try{
       const { data } = await get(BASE_URL+`/chats/chat-messages`,
        {
          headers: { Authorization: `Bearer ${user.accessToken}` },
          params: {
            chat_id: id_chat
          }
        });

      setMessages(data.messages);
      setLoading(false);
      }catch(e){
       
       setLoading(false);
       setError(true);
       setOpenModal(true)
      }
    }
    setQuery("");
    if (id_chat !== "create") {

      setChat(id_chat);

      //Condicional temporal para probar las respuesta con cluster.
      if (id_chat !== "cluster" && user && user.accessToken)
        obtenerMensajes();
      else {
        setMessages([messageCluster]);
        setLoading(false);
      }

    }
    else {
      setMessages([]);
    }
  }, [id_chat, user, refetch]);


  useEffect(() => {
    if (isSending && scrollRef.current && sendingMessageRef.current) {
      scrollRef.current.scrollTo({
        top: sendingMessageRef.current.offsetTop,
        behavior: 'smooth'
      })
    }

  }, [isSending]);



  //Cargando mensajes.
  if (loading && id_chat !== "create") return (<p className="text-white p-2">Cargando mensajes...</p>)

  //Ha ocurrido un problema al cargar los mensajes.
  if(!loading && error) return(
   <> 
    <Error
    
     mensajeError="Ooops !"
     detallesError="Ha ocurrido un problema al intentar cargar sus mensajes"
     accion={(()=>{ reintentarObtenerMensajes()})}
     textoBoton="Volver a intentar"
    />
    <ModalError isOpen={openModal} 
                accion={()=>{setRefetch(true)}} 
                onClose={()=>{setOpenModal(false)}} 
                tituloMensaje="Ooops !"
                mensaje="Ha ocurrido un problema al intentar cargar sus mensajes."
                textoReintentar="Volver a intentar"/> 
   </>)

const steps = [
  {
    locale: { skip: <strong aria-label="skip">OMITIR</strong> },
    content: <h2>BIENVENIDO AL CHAT !</h2>,
    target: "body",
    placement: "center",
  },

  {
    content: "Aqui podras hacer tus preguntas",
    target: ".STEP-QUERY-INPUT"
  },

  {
    content: "Aqui podras ver tus chats recientes y crear una nueva conversacion",
    target: ".STEP-VIEW-CHATS"
  },
]

  //Vista chat.
  return (
    <section className="flex-1 flex flex-col justify-end h-screen p-5 bg-primarycolorback">

      <QueryInput className="STEP-QUERY-INPUT"
        query={query}
        onQuery={setQuery}
        crearChat={crearChat}
        enviarPregunta={enviarPregunta}
        isSending={isSending}
      />

      <div ref={scrollRef} className="overflow-y-scroll mt-6">

        <MessageList
          user={user}
          id_chat={chat}
          messages={messages}
          abrirFuente={abrirFuente}
          userPermission={userPermission}
        />

        <ResponseLoading
          isSending={isSending}
          sendingMessageRef={sendingMessageRef}
        />
      <Tour steps={steps} localStorageKey="KEY-CHAT" />
      </div>

      <ModalError
        isOpen={openErrorSending}
        accion={()=>{enviarPregunta(null, true)}}
        onClose={()=>{setOpenErrorSending(false)}}

        tituloMensaje="Ooops !"
        mensaje="Ha ocurrido un problema al intentar enviar su mensaje."
        textoReintentar="Volver a intentar"
      />

    </section>);
}