import { useState } from "react";
import { Outlet } from "react-router-dom";
import ConfigurationSidebar from "./configurationSidebar";
import { ModalDelete } from "../modalDelete";

export const ConfigurationLayout = (props) => {

    const { user, userRole, userStatus } = props;

    

    const [openModal, setOpenModal] = useState(false);

  
    const abrirModal = () => setOpenModal(true);
    const cerrarModal = () => setOpenModal(false);

    return (
        <div className="flex w-full">

            <ConfigurationSidebar onOpenModal={abrirModal} />
            <Outlet context={[ user, userRole, userStatus]}/>

            <ModalDelete
                pregunta="¿Esta seguro de eliminar su cuenta?"
                parrafo="No se podran recuperar sus datos"
                textoConfirmar="Borrar cuenta"
                textoCancelar="Cancelar"
                isOpen={openModal}
                onClose={cerrarModal}
            />
        </div>
    );
}