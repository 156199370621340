export const UserProductCard = (props) => {
    const {titulo, descripcion, usos, id, onSelectedProduct} = props;
      return (
        <article className="bg-primarycolorcard p-4 w-60 flex flex-col justify-between rounded">
          <section>
            <header className="mb-2 w-full bg-gray-200 rounded h-28 bg-cover" >
              {/* Si hay una imagen: <img src={image} alt={titulo} className="rounded h-full w-full object-cover" /> */}
            </header>
            <h2 className="text-white text-2xl font-bold mb-1 break-words">{titulo}</h2>
            <p className="text-gray-400 font-light mb-2 text-justify">{descripcion}</p>
          </section>
          <footer className="flex justify-between items-center">
            <p className="text-primarycolortext font-bold">Usos: {usos} </p>
            <button
              className="bg-green-500 text-white py-1 px-4 rounded hover:bg-green-600 transition-colors"
              onClick={ () => {onSelectedProduct(id)} }
            >
              Usar
            </button>
          </footer>
        </article>
      )
}