import Tour from "../useTour";
import React from "react";

const steps = [
  {
    title: 'BIENVENIDOO A ADIKE!',
    content: 'DEMOS UNA VUELTA!', 
    locale: { skip: <strong aria-label="skip">OMITIR</strong> },
    placement: "center",
    target: "body",
  },

  {
    content: "Aqui podras tener acceso al chat y hacer tus preguntas",
    target: ".STEP-CHAT-INICIAL"
  },

  {
    content: "Aqui podras cerrar tu sesion",
    target: ".STEP-LOGOUT"
  },

  {
    content: "Aqui podras comprar y actualizar tu suscripcion",
    target: ".STEP-UPGRADE-1",
  },

  {
    content: "Empezemos comprando tu suscripcion!",
    target: ".STEP-UPGRADE-2",
  },
];

const Dashboard = () => {
  return (
    <div className="flex w-full h-screen items-center justify-center flex-col px-12">
      <div className="text-4xl">
        <span className="text-gray-200 font-semibold">Bienvenido a A-Diké</span>
        <span className="text-2xl flex text-red-400 font-semibold"><a href="/actualizar">Revisa nuestros precios por introducción</a></span>
        <span className="text-2xl flex text-gray-200 font-semibold">Preguntame sobre: Legalidades en tu trabajo, en tu matrimonio, con vecinos, con tránsito, con tus impuestos, entre otros.</span>
      </div>
      <Tour steps={steps} localStorageKey="KEY-DASHBOARD" />
    </div>
  );
}

export default Dashboard;
