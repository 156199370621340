import { PlanCard } from "./planCard";
import { Error } from "../error";

export const PlanList = (props) => {

    const { planes, isLoading, error, onSelectedSubscription, refetchSubscriptions } = props;

    if(isLoading) return(<p className="text-white p-1 animate-pulse">Cargando suscripciones disponibles...</p>)

      if(error) return(
        
        <section>
    
        <Error
          mensajeError="Ooops !"
          detallesError={"Ha ocurrido un problema al intentar cargar las suscripciones."}
          textoBoton={"Volver a intentar"}
          accion={refetchSubscriptions}
        />
    
        
      </section>)
    

    return(
    <section className="flex gap-9 flex-wrap justify-center md:justify-start">
        {
         planes.map( plan =>(
            <PlanCard 
              key={plan.id}
              id={plan.id}
              nombre={plan.name}
              descripcion={plan.description}
              precio={plan.price}
              features={plan.features}
              onSelectedSubscription={onSelectedSubscription}
            />
         ))
        }

    </section>)   
}