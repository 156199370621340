export const Modal = (props) => {

  const { active, children, action } = props;
  return (
    <div onClick={(e) => e.target.id === 'modal-parent' && action()} id="modal-parent" className={`${active ? 'visible bg-opacity-50 bg-black h-screen w-full' : 'invisible'} z-50 flex fixed left-0 top-0 items-center justify-center`}>
      <div className="w-80 h-fit block z-50 bg-gray-100 bg-opacity-100 rounded p-0">
        {children}
      </div>
    </div>
  )
};
//export default Modal;